// import AustraliaMap from '@highcharts/map-collection/countries/au/au-all.geo.json';
import AustraliaMap from './custom-map/au-custom.geo.json';
import NewZealandMap from './custom-map/nz-custom.geo.json';
import { BaseChartOptions } from './base-chart.options';
import { ChartOptionsModel } from '../models';

// Function to adjust coordinates
function transformCoordinates(features: any, scale: number, translate: any) {
  return features.map((feature: any) => {
    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: feature.geometry.coordinates.map((coordinateSet: any) => {
          return coordinateSet.map((coordinateGroup: any) => {
            return coordinateGroup.map((coordinatePair: any) => {
              return [
                coordinatePair[0] * scale + translate[0],
                coordinatePair[1] * scale + translate[1]
              ];
            });
          });
        })
      }
    };
  });
}

// Scale and translate New Zealand's map features
const scaledNZFeatures = transformCoordinates(NewZealandMap.features, 0.35, [11000, 500]);

// Combine with Australia's features
const combinedFeatures = [...AustraliaMap.features, ...scaledNZFeatures];

// Create combined map
const AustralasianMap = {
  ...AustraliaMap,
  features: combinedFeatures
};

const chartOptions: Highcharts.Options = {
  ...BaseChartOptions,
  chart: {
    map: AustralasianMap,
    className: 'aus-map-graph',
    // height: (3 / 4 * 100) + '%',
    // height: '50%',  // px
    height: '100%',
    // height: 800,  // px
    // backgroundColor: 'lightgreen', // green sea
  },
  mapNavigation: {
    // enabled: true,
    buttonOptions: { verticalAlign: 'bottom' }
  },
  // colorAxis: { min: 0 },
} as const;

const seriesOptions: Highcharts.PlotMapOptions = {
  states: {
    hover: { color: 'cyan'  },
    select:{ color: 'green' }
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: 'larger',
    },
    formatter: function() {
      if ((<any>this.point)['hc-key'] === 'au-act') {
          return 'ACT';
      }
      // if ((<any>this.point)['hc-key'] === 'au-nf') {
      //     return '';
      // }

        return this.point.name;
    }
    // format: '{point.name}'
  },
  allAreas: true,
  tooltip: {
    pointFormat: '{point.name}<br/><b>Cases:</b> {point.value:.1f}%<br/><b>Top 3 types:</b> {point.top3Types}'
  },
} as const;

export const defaultAusMapChartOptions: ChartOptionsModel = {
  constructorType: 'mapChart',
  chartOptions,
  seriesOptions,
}
