import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BaseGridEditComponent, FilterMatchMode, GridEditorPageStateService, ViewChangeEvent } from '@identic/controls';
import { KeyValuePairs } from '@identic/core';
import { SynonymViewModel, SynonymFacade, SynonymService, SYNONYM_DISPLAY_FIELD } from 'synonym/data-access';
import { listFields } from './editor-fields.data';

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
const COMPONENT_VARS: any = {};

@Component({
  selector: 'synonym-grid-edit',
  templateUrl: './editor.component.html'
})
export class SynonymEditorComponent extends BaseGridEditComponent<SynonymViewModel> implements OnInit {
  @Input() grouping?: string;
  @Input() lookupId?: string;
  @Input() isComponentOnPage?: boolean;
  @Output() editing = new EventEmitter<boolean>();

  listName = 'Synonym';
  initialSortField = SYNONYM_DISPLAY_FIELD;
  itemFields = listFields;

  displayValueFns: KeyValuePairs<Function> = {};

  override CreateNewFormGroupItem(itemData: SynonymViewModel | any = {}): FormGroup {
    // Some defaults. Need to create new object because object is read-only if it comes from the database
    itemData = { ...itemData,
      grouping: itemData.grouping?? COMPONENT_VARS.this.grouping,
      lookup_id: itemData.lookup_id?? COMPONENT_VARS.this.lookupId,
      rule: itemData.rule?? false,
    }

    return super.CreateNewFormGroupItem(itemData);
  }

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,

    service: SynonymService,
    facade: SynonymFacade,
  ) {
    super(route, facade, { service }, { loadOverride: true });
    COMPONENT_VARS.this = this; // For use in CreateNewFormGroupItem called from BaseGridEditComponent

    this.showSingleId = route.snapshot.params?.['id'];
  }

  override ngOnInit(): void {
    this._ignorePageState = !!this.isComponentOnPage;
    this.updateOnly = false;
    super.ngOnInit();
  }

  override preloadCallback(): void {
    this.loadOverride = false;

    if (!this.grouping || !this.lookupId) { return; }  // No filtering required

    // Filter by grouping and lookup_id
    this.addFilter({ field: "grouping", matchMode: FilterMatchMode.equals, value: [this.grouping] });
    this.addFilter({ field: "lookup_id", matchMode: FilterMatchMode.equals, value: [this.lookupId] });
  }

  override onViewChange(event: ViewChangeEvent): void {
    this.editing.emit(event.view === 'detail');
    super.onViewChange(event);
  }
}
// console.log(`%cSynonymGridEditComponent::ngOnInit`, 'background:yellow');
