import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseJsonAssetLoadService } from '@identic/api';
import { ConfigService } from '@identic/core';
import { TutorialConstants } from './data-access.constants';
import { TutorialListModel } from './+state';

// R(oute)T(oken)
const RT = TutorialConstants;

@Injectable({providedIn: 'root'})
export class TutorialService extends BaseJsonAssetLoadService<TutorialListModel> {

  constructor(http: HttpClient, private config: ConfigService) {
    super(http, config.environment.ASSET_ROOT);
  }

}
