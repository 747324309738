import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { GradingEffects } from './+state/grading.effects';
import { GradingFacade } from './+state/grading.facade';
import { GradingReducer } from './+state/grading.reducer';
import { GRADING_FEATURE_KEY, GradingInitialState as initialState } from './+state/grading.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForGrading = StoreModule.forFeature(GRADING_FEATURE_KEY, GradingReducer, { initialState });
export const effectsModuleForGrading = EffectsModule.forFeature([GradingEffects]);

const PROVIDERS = [GradingFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForGrading,
    effectsModuleForGrading,
  ],
  providers: PROVIDERS,
})
export class GradingDataAccessModule {}
