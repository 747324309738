import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { TemplateDisplayModule } from '@identic/templates';
import { CaseEditorModule } from 'case/features';
import { DashboardConstants } from 'dashboard/data-access';
import { ImportDataAccessModule } from 'import/data-access';
import { PatientEditorModule } from 'patient/features';
import { SampleEditorModule } from 'sample/features';
import { SharedModule } from 'shared';
import { ImportEditorComponent } from './editor';
import { GetMessageClassPipe, GetMessageIconPipe, JsonMessageComponent } from './json-message';
import { GetCaseFGPipe, GetCasePipe, GetJsonMessagesPipe, GetJsonMessagesTypesPipe } from './pipes';
import { UploadExcelComponent } from './upload-excel';
import { ValidationErrorsComponent } from './validation-errors';

const COMPONENTS = [ImportEditorComponent, JsonMessageComponent, UploadExcelComponent, ValidationErrorsComponent];
const PIPES = [
  GetCasePipe,
  GetCaseFGPipe,
  GetJsonMessagesPipe,
  GetJsonMessagesTypesPipe,
  GetMessageClassPipe,
  GetMessageIconPipe,
];

export const routerModuleForChild = RouterModule.forChild([
  { path: DashboardConstants.UI.UPLOAD_PATH, component: ImportEditorComponent, canActivate: [AuthGuard] },
  { path: '', component: ImportEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),
    TemplateDisplayModule,

    SharedModule,
    ImportDataAccessModule,
    PatientEditorModule,
    CaseEditorModule,
    SampleEditorModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
  ],
  exports: [COMPONENTS]
})
export class ImportModule {}
