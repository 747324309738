import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { SpeciesDataAccessModule } from 'species/data-access';
import { SpeciesGuard } from './selector.guard';
import { SpeciesSelectorComponent } from './selector.component';

const COMPONENTS = [SpeciesSelectorComponent];
const PROVIDERS = [SpeciesGuard];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    SpeciesDataAccessModule,
  ],
  providers: PROVIDERS,
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SpeciesSelectorModule {}
