import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseGridEditComponent, GridEditorPageStateService } from '@identic/controls';
import { KeyValuePairs } from '@identic/core';
import { DataSourceViewModel, DataSourceFacade, DataSourceService, DATA_SOURCE_DISPLAY_FIELD } from 'data-source/data-access';
import { listFields } from './editor-fields.data';

@Component({
  selector: 'data-source-grid-edit',
  templateUrl: './editor.component.html'
})
export class DataSourceEditorComponent extends BaseGridEditComponent<DataSourceViewModel> implements OnInit {
  listName = 'DataSource';
  initialSortField = DATA_SOURCE_DISPLAY_FIELD;
  itemFields = listFields;


  displayValueFns: KeyValuePairs<Function> = {};

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,

    // private errorService: ErrorService,
    service: DataSourceService,
    facade: DataSourceFacade,
  ) {
    super(route, facade, { service });

    this.showSingleId = route.snapshot.params?.['id'];
  }

  override onCreate(entity: DataSourceViewModel): void {
    // Popup doesn't work as the reload returns OK before the error comes back.
    // this.errorService.showInPopup({ rememberErrors: true, errorDialog: { iconText: `Unable to create data source`}});
    super.onCreate(entity);
  }
}
// console.log(`%cDataSourceGridEditComponent::ngOnInit`, 'background:yellow');
