import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { TemplateDisplayModule } from '@identic/templates';
import { DataSourceGuard, DataSourceSelectorModule } from 'data-source/features';
import { DiagnosisEditorModule } from 'diagnosis/features';
import { DashboardConstants } from 'dashboard/data-access';
import { DashboardSharedModule } from './shared';
import { DashboardEditorComponent, DashboardEditorModule } from './editor';
import { DashboardUploadComponent } from './upload';
import { DashboardReportComponent } from './report';

// R(oute)T(oken)
const RT = DashboardConstants;

const COMPONENTS = [DashboardReportComponent];

export const routerModuleForChild = RouterModule.forChild([
  { path: '', component: DashboardEditorComponent, canActivate: [AuthGuard, DataSourceGuard] },
  { path: RT.UI.UPLOAD_PATH, component: DashboardUploadComponent, canActivate: [AuthGuard, DataSourceGuard] },
  { path: RT.UI.REPORT_PATH + '/:dataSourceId/:species/:startDate/:endDate', component: DashboardReportComponent },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),

    HighchartsChartModule,
    TemplateDisplayModule,
    DiagnosisEditorModule,
    DataSourceSelectorModule,
    DashboardSharedModule,
    DashboardEditorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DashboardModule {}
