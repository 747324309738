export const ImportConstants = {
  // Route Tokens
  defaultApi: 'Import',

	ALL: 'all',
	COLOURISE: 'colourise',
	COMMIT: 'commit',
	DATA_SOURCE: 'data_source',
	DELETE_MANY: 'delete-many',
	EMAIL: 'email',
	END: 'end',
	GROUP: 'group',
	LOOKUP: 'lookup',
	RANDOM: 'random',
	SAMPLE_METASTASIS: 'sample_metastasis',
	SELECTED: 'selected',
	SPECIES: 'species',
	START: 'start',

  // Other constants
  CANINE: 'Canine',
  FELINE: 'Feline',

  UI: {
    ROOT_PATH: 'import',
  },

  // IMPORT_ACCEPTS: ['*'], // ["officedocument.spreadsheetml.sheet","application/vnd.openxmlformats","application/vnd.ms-excel"],
  IMPORT_ACCEPTS: [".xls,.xlsx"],

} as const;

export const EXCEL_IMPORT_ACTION = {
  actionName: 'excel-import-event',
  title: 'Import Excel file',
  icon: 'add',
  // class: 'text-bg-success',
  class: 'grid-header-row',
};

export const COMMIT_SELECTED_IMPORTS_ACTION = {
  actionName: 'commit-selected-imports-event',
  title: 'Commit selected imports',
  icon: 'cloud_upload',
  // class: 'text-bg-success',
  class: 'grid-header-row',
};

export const DELETE_SELECTED_IMPORTS_ACTION = {
  actionName: 'delete-selected-imports-event',
  title: 'Delete selected imports',
  iconAwesome: 'fas fa-trash-can',
  // class: 'text-bg-danger',
  class: 'grid-header-row',
};
