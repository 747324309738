import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { safeValue, ConfigService, buildPath, formatUrlDate } from '@identic/core';
import { ReportConstants } from './report.constants';

const RT = ReportConstants;

@Injectable({providedIn: 'root'})
export class ReportService {

  downloadFileOptions: any = { observe: "response", responseType: 'blob' as 'json' };

  apiUrl!: string;
  constructor(private http: HttpClient, config: ConfigService) {
    this.apiUrl = buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Reports', RT.defaultApi));
  }

  public getReportData(url: string): Observable<any> {
    return this.http.get(buildPath(this.apiUrl, url));
  }

  public downloadExcelFile(start_date: Date, end_date: Date, dataSourceId?: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(buildPath(this.apiUrl, 'Excel', dataSourceId ? buildPath(RT.DATA_SOURCE, dataSourceId) : null, RT.START, formatUrlDate(start_date), RT.END, formatUrlDate(end_date)), { observe: "response", responseType: 'blob' as 'json' });
  }

  public downloadPdfFile(filename: string, url: string, tokens: any): Observable<HttpEvent<Blob>> {
    const pdfParams: any = {
      page2PdfFilename: filename,
      page2PdfUrl: buildPath(window.location.origin, url),
      tokens
    };
    return this.http.put<Blob>(buildPath(this.apiUrl, RT.DOWNLOAD_PDF), pdfParams, { observe: "response", responseType: 'blob' as 'json' });
  }

  public downloadArchivedFile(caseId?: string, isCase: boolean = true): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(buildPath(this.apiUrl, RT.EXCEL, (isCase ? RT.CASE : RT.IMPORT), caseId), { observe: "response", responseType: 'blob' as 'json' });
  }

}
// console.log(`%cReportService::downloadPdfFile`, 'background:cyan');
