import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SpeciesEffects } from './+state/species.effects';
import { SpeciesFacade } from './+state/species.facade';
import { SpeciesReducer } from './+state/species.reducer';
import { SPECIES_FEATURE_KEY, SpeciesInitialState as initialState } from './+state/species.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForSpecies = StoreModule.forFeature(SPECIES_FEATURE_KEY, SpeciesReducer, { initialState });
export const effectsModuleForSpecies = EffectsModule.forFeature([SpeciesEffects]);

const PROVIDERS = [SpeciesFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForSpecies,
    effectsModuleForSpecies,
  ],
  providers: PROVIDERS,
})
export class SpeciesDataAccessModule {}
