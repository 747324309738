import { SelectOption } from "@identic/controls";

export const DataSourceConstants = {
  // Route Tokens
  defaultApi: 'DataSource',

//  APPLICATION: "application",
//  GROUP: "group",
//  OLDER_THAN: "olderThan",
//  ROLES: "roles",

  // Other constants
  UI: {
    ROOT_PATH: 'data-source',
  },
} as const;

export const COUNTRY_CODE_OPTIONS: SelectOption[] = [
  { value: 'AUS' },
  { value: 'NZL' },
]
