import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';

import { CustomControlsModule } from '@identic/controls';
import { ChartOptionsPipe, FormatDashboardHeaderPipe, ReportChartComponent } from '../shared';
import { ReportTableComponent } from '../report';

const COMPONENTS = [ReportChartComponent, ReportTableComponent];
const PIPES = [ChartOptionsPipe, FormatDashboardHeaderPipe];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    HighchartsChartModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES,
  ]
})
export class DashboardSharedModule {}
