import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PatientEffects } from './+state/patient.effects';
import { PatientFacade } from './+state/patient.facade';
import { PatientReducer } from './+state/patient.reducer';
import { PATIENT_FEATURE_KEY, PatientInitialState as initialState } from './+state/patient.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForPatient = StoreModule.forFeature(PATIENT_FEATURE_KEY, PatientReducer, { initialState });
export const effectsModuleForPatient = EffectsModule.forFeature([PatientEffects]);

const PROVIDERS = [PatientFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForPatient,
    effectsModuleForPatient,
  ],
  providers: PROVIDERS,
})
export class PatientDataAccessModule {}
