import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { DashboardService, ReportConstants } from 'dashboard/data-access';
import { DataSourceService, DataSourceViewModel } from 'data-source/data-access';
import { ageChartOptions, ausMapChartOptions, breedChartOptions, diagnosisChartOptions, genderChartOptions, topographyChartOptions, trendChartOptions } from '../shared';

@Component({
  selector: 'dashboard-report',
  templateUrl: 'report.component.html',
  styleUrls: ['report.component.scss'],
})
export class DashboardReportComponent {
  dashboardItems$: Observable<string> = this.service.getDashboardItems();
  selectedDataSource$: Observable<DataSourceViewModel> = this.dataSourceService.get(this.route.snapshot.params?.['dataSourceId'])
  startDate: Date = this.route.snapshot.params?.['startDate'];
  endDate: Date = this.route.snapshot.params?.['endDate'];
  species: string = this.route.snapshot.params?.['species'];

  widgetClass = 'col-12 shadow-none';

  // For use in template
  RT = ReportConstants;

  // Graph settings for template use
  diagnosisChartOptions = diagnosisChartOptions;
  topographyChartOptions = topographyChartOptions;
  ausMapChartOptions = ausMapChartOptions;
  breedChartOptions = breedChartOptions;
  genderChartOptions = genderChartOptions;
  ageChartOptions = ageChartOptions;
  trendChartOptions = trendChartOptions;

  constructor(
    private route: ActivatedRoute,
    private service: DashboardService,
    private dataSourceService: DataSourceService,
  ) {}
}
// console.log(`%cDashboardGridEditComponent::ngOnInit`, 'background:yellow');
