import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorialService } from './data-access.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    TutorialService
  ],
})
export class TutorialDataAccessModule {}
