export const UserSubscriptionConstants = {
  // Route Tokens
  defaultApi: 'Other',

  SUBSCRIBE: 'subscribe',

  // Other constants
  UI: {
    ROOT_PATH: 'user-subscription',
  }

} as const;
