import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { UserSubscriptionConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = UserSubscriptionConstants;

@Injectable({providedIn: 'root'})
export class UserSubscriptionService extends BaseService<any> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.UserSubscription', RT.defaultApi)));
  }
}
