<ng-container *ngIf="selectedDataSource$ | async; let selectedDataSource">
  <div class="container-fluid mx-5">
    <ng-container *ngFor="let dashboardItem of (dashboardItems$ | async); let lastPage=last">
      <ng-container [ngSwitch]="dashboardItem">

        <mat-card *ngSwitchCase="RT.DIAGNOSIS" [class]="widgetClass" [ngClass]="{'page-break': !lastPage}">
          <mat-card-content *ngIf="10; let topCount">
            <div class="row">
              <report-chart class="col-10"
                    [id]="dashboardItem"
                    graphHeight="400"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [topCount]="topCount"
                    [chartOptions]="diagnosisChartOptions"
                    [reportRoute]="RT.DIAGNOSIS">
                <div class="d-flex">
                  <h3 class="mx-auto">Top {{topCount}} types (morphology)</h3>
                </div>
              </report-chart>
            </div>
            <div class="row">
              <report-table class="col-10"
                  [id]="dashboardItem"
                  [species]="species"
                  [dataSource]="selectedDataSource"
                  [startDate]="startDate"
                  [endDate]="endDate"
                  [topCount]="topCount"
                  [cols]="{name: 'Morphology', y: 'Total'}"
                  [reportRoute]="RT.DIAGNOSIS">
              </report-table>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="RT.TOPOGRAPHY" [class]="widgetClass" [ngClass]="{'page-break': !lastPage}">
          <mat-card-content *ngIf="10; let topCount">
            <div class="row">
              <report-chart class="col-10"
                    [id]="dashboardItem"
                    graphHeight="400"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [topCount]="topCount"
                    [chartOptions]="topographyChartOptions"
                    [reportRoute]="RT.TOPOGRAPHY">
                <div class="d-flex">
                  <h3 class="mx-auto">Top {{topCount}} anatomical sites (topography)</h3>
                </div>
              </report-chart>
            </div>
            <div class="row">
              <report-table class="col-10"
                    [id]="dashboardItem"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [topCount]="topCount"
                    [cols]="{name: 'Topography', y: 'Total'}"
                    [reportRoute]="RT.TOPOGRAPHY">
              </report-table>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="RT.BREED" [class]="widgetClass" [ngClass]="{'page-break': !lastPage}">
          <mat-card-content *ngIf="10; let topCount">
            <div class="row">
              <report-chart class="col-10"
                    [id]="dashboardItem"
                    graphHeight="400"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [topCount]="topCount"
                    [chartOptions]="breedChartOptions"
                    [reportRoute]="RT.BREED">
                <div class="d-flex">
                  <h3 class="mx-auto">Top {{topCount}} breeds</h3>
                </div>
              </report-chart>
            </div>
            <div class="row">
              <report-table class="col-10"
                    [id]="dashboardItem"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [topCount]="topCount"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [topCount]="topCount"
                    [cols]="{name: 'Breed', y: 'Total'}"
                    [reportRoute]="RT.BREED">
              </report-table>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="RT.GENDER" [class]="widgetClass" [ngClass]="{'page-break': !lastPage}">
          <mat-card-content>
            <div class="row">
              <report-chart class="col-10"
                    [id]="dashboardItem"
                    graphHeight="400"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [chartOptions]="genderChartOptions"
                    [reportRoute]="RT.GENDER">
                <div class="d-flex">
                  <h3 class="mx-auto">Sex</h3>
                </div>
              </report-chart>
            </div>
            <div class="row">
              <report-table class="col-10"
                    [id]="dashboardItem"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [cols]="{name: 'Sex', value: 'Total'}"
                    [reportRoute]="RT.GENDER">
              </report-table>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="RT.AGE" [class]="widgetClass" [ngClass]="{'page-break': !lastPage}">
          <mat-card-content>
            <div class="row">
              <report-chart class="col-10"
                    [id]="dashboardItem"
                    graphHeight="400"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [chartOptions]="ageChartOptions"
                    [reportRoute]="RT.AGE">
                <div class="d-flex">
                  <h3 class="mx-auto">Average age at diagnosis</h3>
                </div>
              </report-chart>
            </div>
            <div class="row">
              <report-table class="col-10"
                    [id]="dashboardItem"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [cols]="{name: 'Age', y: 'Total'}"
                    [reportRoute]="RT.AGE">
              </report-table>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="'monthly-trend'" [class]="widgetClass" [ngClass]="{'page-break': !lastPage}">
          <mat-card-content>
            <div class="row">
              <report-chart class="col-10"
                    [id]="dashboardItem"
                    graphHeight="400"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [chartOptions]="trendChartOptions"
                    [reportRoute]="RT.CASE">
                <div class="d-flex">
                  <h3 class="mx-auto">Monthly Trend</h3>
                </div>
              </report-chart>
            </div>
            <div class="row">
              <report-table class="col-10"
                    [id]="dashboardItem"
                    [species]="species"
                    [dataSource]="selectedDataSource"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [cols]="{name: 'Date', y: 'Total'}"
                    [reportRoute]="RT.CASE">
              </report-table>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<footer>
  <!-- <template-display templateName="PublicAcknowledgements"></template-display> -->
  <template-display templateName="ReportPDFRendered"></template-display>
</footer>
