import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SynonymEffects } from './+state/synonym.effects';
import { SynonymFacade } from './+state/synonym.facade';
import { SynonymReducer } from './+state/synonym.reducer';
import { SYNONYM_FEATURE_KEY, SynonymInitialState as initialState } from './+state/synonym.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForSynonym = StoreModule.forFeature(SYNONYM_FEATURE_KEY, SynonymReducer, { initialState });
export const effectsModuleForSynonym = EffectsModule.forFeature([SynonymEffects]);

const PROVIDERS = [SynonymFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForSynonym,
    effectsModuleForSynonym,
  ],
  providers: PROVIDERS,
})
export class SynonymDataAccessModule {}
