import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import { SampleMetastasisShallowViewModel, SampleViewModel } from 'sample/data-access';
import { SampleEditorComponent } from './editor.component';

export function primaryNotInSecondaryValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const primaryCtlName = "topography_primary";

    // Make sure we can find other controls through the parent
    if (!control.parent) { return null; }

    const parent = control.parent!;

    // If the secondary changed then re-validate the primary
    const primaryCtl = parent.get(primaryCtlName)!;
    if (control !== primaryCtl) {
      primaryCtl.markAsTouched();   // Show the validation status
      primaryCtl.updateValueAndValidity();   // Show the validation status
      return null;
    }

    const item: SampleViewModel = parent.value;
    // Are there values for the primary and secondary?
    if ((typeof(item.topography_primary) !== 'object') || !Array.isArray(item.sample_metastases)) {
      return null;
    }

    const primaryIdxInSecondary = item.sample_metastases.findIndex((s: SampleMetastasisShallowViewModel) => s.topography?.id === item.topography_primary?.id);

    return (primaryIdxInSecondary === -1) ? null : { "Primary site cannot also be in secondary site.": true };
  }
}

export function uniqueSampleValuesValidator(thisSample: any, sampleEditorComponent: SampleEditorComponent): boolean {
  const existingSamples: SampleViewModel[] = sampleEditorComponent.samples??[];

    // Helper function to determine if two sample_metastases arrays are equal based on topography_id
    const areSampleMetastasesEqualFn = function (arr1: SampleMetastasisShallowViewModel[], arr2: SampleMetastasisShallowViewModel[]): boolean {
      if (arr1.length !== arr2.length) return false;

      const sortedArr1 = arr1.map(m => m.topography?.id).sort();
      const sortedArr2 = arr2.map(m => m.topography?.id).sort();
      return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
    }

    // Find duplicates based on your criteria
    const duplicateSamples = existingSamples.filter(sample =>
      // Exclude this sample
      sample.sample_name?.toLowerCase() !== thisSample.sample_name?.toLowerCase() &&

      // Matching on diagnosis, topography_primary, and the array of sample_metastases.topography.id?
      sample.diagnosis?.id === thisSample.diagnosis?.id &&
      sample.topography_primary?.id === thisSample.topography_primary?.id &&
      areSampleMetastasesEqualFn(sample.sample_metastases??[], thisSample.sample_metastases??[])
    );

    return duplicateSamples.length > 0;
}
