import { Injectable } from "@angular/core";
import { KeyValuePairs } from "@identic/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ReportChartSelectionService {
  private _selectedPointsSubject = new BehaviorSubject<KeyValuePairs<string>>({});
  selectedPoints$ = this._selectedPointsSubject.asObservable();

  // Select a point by its name
  selectPoint(graphId: string, pointName: string): void {
    const currentSelections = this._selectedPointsSubject.value;
    currentSelections[graphId] = pointName;
    // Emit the updated state
    this._selectedPointsSubject.next(currentSelections);
  }

  // Check if any selections exist for this graph
  selectionsExist(graphId: string): boolean {
    const currentSelections = this._selectedPointsSubject.value;
    return !!currentSelections[graphId];
  }

  // Check if a point is selected by its name
  isSelected(graphId: string, pointName: string): boolean {
    const currentSelections = this._selectedPointsSubject.value;
    return currentSelections[graphId] === pointName;
  }

  // Get selection
  getSelection(graphId: string): string {
    const currentSelections = this._selectedPointsSubject.value;
    return currentSelections[graphId];
  }

  // Deselect a point by its name
  deselectPoint(graphId: string): void {
    const currentSelections = this._selectedPointsSubject.value;

    if (currentSelections[graphId]) {
      delete currentSelections[graphId];
      // Emit the updated state
      this._selectedPointsSubject.next(currentSelections);
    }
  }

  // Clear all selections for all charts
  clearAllSelections(): void {
    this._selectedPointsSubject.next({});
  }

  // Get all selections as KeyValuePairs
  getAllSelections(): KeyValuePairs<string> {
    const currentSelections = this._selectedPointsSubject.value;
    return currentSelections;
  }
}
// console.log(`%cReportSelectionService::`, 'background:yellow');
