import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DiagnosisEffects } from './+state/diagnosis.effects';
import { DiagnosisFacade } from './+state/diagnosis.facade';
import { DiagnosisReducer } from './+state/diagnosis.reducer';
import { DIAGNOSIS_FEATURE_KEY, DiagnosisInitialState as initialState } from './+state/diagnosis.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForDiagnosis = StoreModule.forFeature(DIAGNOSIS_FEATURE_KEY, DiagnosisReducer, { initialState });
export const effectsModuleForDiagnosis = EffectsModule.forFeature([DiagnosisEffects]);

const PROVIDERS = [DiagnosisFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForDiagnosis,
    effectsModuleForDiagnosis,
  ],
  providers: PROVIDERS,
})
export class DiagnosisDataAccessModule {}
