import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AsFormGroupPipe, GetColsByRowPipe, GetRowNumbersPipe } from './pipes';

const PIPES = [
  AsFormGroupPipe,
  GetColsByRowPipe,
  GetRowNumbersPipe,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [PIPES],
  exports: [PIPES]
})
export class SharedModule {}
