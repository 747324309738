import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LocationEffects } from './+state/location.effects';
import { LocationFacade } from './+state/location.facade';
import { LocationReducer } from './+state/location.reducer';
import { LOCATION_FEATURE_KEY, LocationInitialState as initialState } from './+state/location.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForLocation = StoreModule.forFeature(LOCATION_FEATURE_KEY, LocationReducer, { initialState });
export const effectsModuleForLocation = EffectsModule.forFeature([LocationEffects]);

const PROVIDERS = [LocationFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForLocation,
    effectsModuleForLocation,
  ],
  providers: PROVIDERS,
})
export class LocationDataAccessModule {}
