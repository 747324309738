import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NxModule } from '@nrwl/nx';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreServicesModule, ConfigService  } from '@identic/core';
import { ApiModule } from '@identic/api';
import { AuthIdServer4Module } from '@identic/auth';
import { CustomControlsModule } from '@identic/controls';
import { AppLayoutModule } from '@identic/layout';
import { TemplateDisplayModule } from '@identic/templates';
import { IdenticProductSelectorModule } from '@identic/licence';

import { environment } from '../environments';
import { AppComponent } from './app.component';
import { menuItems } from './app.routing';
import { HomeComponent } from './home.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardEditorModule } from 'dashboard/features';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

export const COMPONENTS = [AppComponent, HomeComponent];

// Fix for AOT compiler error: Function calls are not supported in decorators but 'RouterModule' was called.
export const storeModuleForRoot = StoreModule.forRoot({},
  { metaReducers: !environment.production ? [] : [], runtimeChecks: { strictStateImmutability: true, strictActionImmutability: false } }
);
export const effectsModuleForRoot = EffectsModule.forRoot([]);
export const storeRouterConnectingModuleForRoot = StoreRouterConnectingModule.forRoot();
export const apiModuleForRoot = ApiModule.forRoot();
export const customControlsModuleForRoot = CustomControlsModule.forRoot();
export const nxModuleForRoot = NxModule.forRoot();
export const coreServicesModuleForRoot = CoreServicesModule.forRoot({ environment, menuItems }); // Make app config available to libs. NOTE: AOT compiler option requires configService.init() below
export const authIdServer4ModuleForRoot = AuthIdServer4Module.forRoot();

@NgModule({
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, // Must only be in app.module for interceptors to work
    storeModuleForRoot,
    effectsModuleForRoot,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    storeRouterConnectingModuleForRoot,
    nxModuleForRoot,

    AppLayoutModule,
    coreServicesModuleForRoot,
    authIdServer4ModuleForRoot,
    apiModuleForRoot,
    customControlsModuleForRoot,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,

    // Modules NOT lazy loaded
    TemplateDisplayModule,
    IdenticProductSelectorModule,
    DashboardEditorModule,
    //ChatModule,
    AppRoutingModule, // MUST be last!!
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: environment.VIRTUAL_DIR || '/' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
  ],
  declarations: COMPONENTS,
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(configService: ConfigService) {
    configService.init({ environment, menuItems });
  }
}
