import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CertaintyLevelEffects } from './+state/certainty-level.effects';
import { CertaintyLevelFacade } from './+state/certainty-level.facade';
import { CertaintyLevelReducer } from './+state/certainty-level.reducer';
import { CERTAINTY_LEVEL_FEATURE_KEY, CertaintyLevelInitialState as initialState } from './+state/certainty-level.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForCertaintyLevel = StoreModule.forFeature(CERTAINTY_LEVEL_FEATURE_KEY, CertaintyLevelReducer, { initialState });
export const effectsModuleForCertaintyLevel = EffectsModule.forFeature([CertaintyLevelEffects]);

const PROVIDERS = [CertaintyLevelFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForCertaintyLevel,
    effectsModuleForCertaintyLevel,
  ],
  providers: PROVIDERS,
})
export class CertaintyLevelDataAccessModule {}
