export const TutorialConstants = {
  // Route Tokens
  // defaultApi: 'Tutorials',

  // APPLICATION: "application",


  // Other constants
  UI: {
    ROOT_PATH: 'tutorials',
  }

} as const;
