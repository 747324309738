import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TopographyEffects } from './+state/topography.effects';
import { TopographyFacade } from './+state/topography.facade';
import { TopographyReducer } from './+state/topography.reducer';
import { TOPOGRAPHY_FEATURE_KEY, TopographyInitialState as initialState } from './+state/topography.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForTopography = StoreModule.forFeature(TOPOGRAPHY_FEATURE_KEY, TopographyReducer, { initialState });
export const effectsModuleForTopography = EffectsModule.forFeature([TopographyEffects]);

const PROVIDERS = [TopographyFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForTopography,
    effectsModuleForTopography,
  ],
  providers: PROVIDERS,
})
export class TopographyDataAccessModule {}
