import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseGridEditComponent, GridEditorPageStateService } from '@identic/controls';
import { KeyValuePairs } from '@identic/core';
import { SpeciesViewModel, SpeciesFacade, SpeciesService, SPECIES_DISPLAY_FIELD } from 'species/data-access';
import { listFields } from './editor-fields.data';

@Component({
  selector: 'species-grid-edit',
  templateUrl: './editor.component.html'
})
export class SpeciesEditorComponent extends BaseGridEditComponent<SpeciesViewModel> implements OnInit {
  listName = 'Species';
  initialSortField = SPECIES_DISPLAY_FIELD;
  itemFields = listFields;

  displayValueFns: KeyValuePairs<Function> = {};

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,

    service: SpeciesService,
    facade: SpeciesFacade,
  ) {
    super(route, facade, { service });

    this.showSingleId = route.snapshot.params?.['id'];
  }
}
// console.log(`%cSpeciesGridEditComponent::ngOnInit`, 'background:yellow');
