import { GridEditorField, ControlType, FilterMatchMode, CreateSelectOptionsFromEnum } from '@identic/controls';
import { COMMIT_SELECTED_IMPORTS_ACTION, DELETE_SELECTED_IMPORTS_ACTION, EXCEL_IMPORT_ACTION, ImportDuplicateStatus, ImportParseStatus, ImportReadyStatus } from 'import/data-access';


export const DATA_SOURCE_DDL_FIELD = 'data_source_id';
export const PROCESSED_JSON_FIELD = 'processed_json';
export const SPECIES_FIELD = 'species';
export const READY_STATUS_FIELD = 'ready_status';
export const PARSE_STATUS_FIELD = 'parse_status';
export const DUPLICATE_STATUS_FIELD = 'duplicate_status';
export const COMMIT_MESSAGES_FIELD = 'commit_messages';
export const SOURCE_FILENAME_FIELD = 'source_filename';

    // private dataSourceService: DataSourceService,
    // this.displayValueFns[DATA_SOURCE_DDL_FIELD] = (col: GridEditorField, item: DataSourceShallowViewModel) => item[DATA_SOURCE_DISPLAY_FIELD];
    // this.subscriptions.push(this.dataSourceService.getAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<DataSourceShallowViewModel>) => {
    //   this.itemFields.find((i: GridEditorField) => i.field === DATA_SOURCE_DDL_FIELD)!.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, pagedResults.results, DATA_SOURCE_KEY_FIELD);
    // }));

export const listFields: GridEditorField[] = [
  {
    field: '_selected', header: 'Select',
    gridEdit: false, gridClass: 'col-md-1',
    detailHide: true,
    actions: {
      showSelect: true,
    },
    controlType: ControlType.custom,
  },
  { // type: Lookup
    field: DATA_SOURCE_DDL_FIELD, header: 'Data Source',
    filterMatchMode: FilterMatchMode.equals, sortable: true,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4 bold-label', detailEdit: false,
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  {
    field: 'reference_code', header: 'Reference',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailHide: true,
  },
  { field: 'data_source', header: '', gridHide: true, detailHide: true },
  {
    field: SOURCE_FILENAME_FIELD, header: 'File',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1 ',
    detailRow: 0, detailClass: 'col-md-6 bold-label', detailEdit: false,
    // controlType: ControlType.template,
  },
  { // type: number
    field: 'source_row', header: 'Row',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-2 bold-label', detailEdit: false,
    filterType: ControlType.dropdown,
  },
  {
    field: SPECIES_FIELD, header: 'Species',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailHide: true,
    filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: 'raw_json', header: '', gridHide: true, detailHide: true },
  { field: PROCESSED_JSON_FIELD, header: '', gridHide: true, detailHide: true },
  {
    field: READY_STATUS_FIELD, header: 'Ready',
    filterMatchMode: FilterMatchMode.equals, sortable: true,
    gridClass: 'col-md-1',
    detailHide: true,
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: CreateSelectOptionsFromEnum(ImportReadyStatus),
  },
  {
    field: PARSE_STATUS_FIELD, header: 'Parse',
    filterMatchMode: FilterMatchMode.equals, sortable: true,
    gridClass: 'col-md-1',
    detailHide: true,
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: CreateSelectOptionsFromEnum(ImportParseStatus),
  },
  {
    field: DUPLICATE_STATUS_FIELD, header: 'Status',
    filterMatchMode: FilterMatchMode.equals, sortable: true,
    gridClass: 'col-md-1',
    detailHide: true,
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: CreateSelectOptionsFromEnum(ImportDuplicateStatus),
  },
  { field: COMMIT_MESSAGES_FIELD, header: '', gridHide: true, detailHide: true },
  { field: 'parser_messages', header: '', gridHide: true, detailHide: true },
  { // type: Date
    field: 'date_added', header: 'Uploaded',
    sortable: true, filterMatchMode: FilterMatchMode.between,
    gridClass: 'col-md-1',
    detailHide: true,
    // detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.calendar,
    data: { dateFormat: 'd MMM y' },
  },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-2',
    detailHide: true,
    actions: {
      showAdd: false,
      showDel: { class: 'grid-header-row' },
      showEdit: { class: 'grid-header-row' },
      showView: false,
      showReload: false,
      headerAction: [
        EXCEL_IMPORT_ACTION,
        COMMIT_SELECTED_IMPORTS_ACTION,
        DELETE_SELECTED_IMPORTS_ACTION,
      ],
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
