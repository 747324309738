<grid-editor
  [dataReady]="!!patient || (loaded$ | async)" [dataList]="patient || (list$ | async)"
  [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
  [itemFields]="itemFields" [services]="services" [updateOnly]="updateOnly" [listName]="listName"
  [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId" [(currentView)]="currentView"
  [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
  [listIcon]="(route.data | async)!['icon']" [listIconAwesome]="(route.data | async)!['faIcon']"
  [getDisplayValueFns]="displayValueFns"
  [headerSuffix]="selectedItemName"

  (viewChange)="onViewChange($event)"
  (pageChange)="onPageChange($event)"
  (customAction)="customAction($event)"
  (create)="onCreate($event)"
  (update)="onUpdate($event)"
  (delete)="onDelete($event)"
  (reload)="onReload($event)">

  <span class="header-content d-flex">

    <species-selector class="me-2" (change)="speciesChanged($event)"></species-selector>

    <mat-form-field class="me-2">
      <mat-label class="small">Diagnosis Filter</mat-label>
      <input id="diagnosisFilter" matInput controlType="text"
            placeholder="Diagnosis or code"
            [(ngModel)]="diagnosisFilter">
      <button *ngIf="!!diagnosisFilter" matSuffix mat-icon-button aria-label="Clear" title="Clear diagnosis filter" (click)="diagnosisFilter = undefined">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="me-2">
      <mat-label class="small">Topography Filter</mat-label>
      <input id="topographyFilter" matInput controlType="text"
            placeholder="Topography or code"
            [(ngModel)]="topographyFilter">
      <button *ngIf="!!topographyFilter" matSuffix mat-icon-button aria-label="Clear" title="Clear topography filter" (click)="topographyFilter = undefined">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button mat-fab color="primary" (click)="onApplyFilters()" title="Apply all filters">
      <i class="fas fa-filter"></i>
    </button>
  </span>

</grid-editor>
