import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BreedEffects } from './+state/breed.effects';
import { BreedFacade } from './+state/breed.facade';
import { BreedReducer } from './+state/breed.reducer';
import { BREED_FEATURE_KEY, BreedInitialState as initialState } from './+state/breed.state';

const PROVIDERS = [BreedFacade];

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForBreed = StoreModule.forFeature(BREED_FEATURE_KEY, BreedReducer, { initialState });
export const effectsModuleForBreed = EffectsModule.forFeature([BreedEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForBreed,
    effectsModuleForBreed,
  ],
  providers: PROVIDERS,
})
export class BreedDataAccessModule {}
