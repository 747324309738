import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';

import { PagedResult } from '@identic/api';
import { BaseGridEditComponent, GridEditorPageStateService, GridEditorField, CreateSelectOptionsFromList, MapFiltersToPageFilters, FilterMatchMode, ViewChangeEvent } from '@identic/controls';
import { buildPath, KeyValuePairs, NewKVP, safeDelete, safeObject, setSafeValue } from '@identic/core';
import { BreedService, BreedShallowViewModel, BREED_DISPLAY_FIELD, BREED_KEY_FIELD } from 'breed/data-access';
import { CaseConstants, SHOW_CASES_ACTION } from 'case/data-access';
import { DataSourceService, DataSourceViewModel, DATA_SOURCE_DISPLAY_FIELD, DATA_SOURCE_KEY_FIELD } from 'data-source/data-access';
import { PatientViewModel, PatientFacade, PatientService, PatientConstants, PATIENT_DISPLAY_FIELD, PATIENT_KEY_FIELD } from 'patient/data-access';
import { SpeciesFacade, SpeciesService, SpeciesViewModel, SPECIES_DISPLAY_FIELD, SPECIES_KEY_FIELD } from 'species/data-access';
import { listFields, BREED_DDL_FIELD, CROSS_BREED_DDL_FIELD, DATA_SOURCE_DDL_FIELD, SPECIES_DDL_FIELD, SPECIES_FIELD } from './editor-fields.data';

// R(oute)T(oken)
const RT = PatientConstants;

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
let COMPONENT_THIS: any = {};

@Component({
  selector: 'patient-grid-edit',
  templateUrl: './editor.component.html'
})
export class PatientEditorComponent extends BaseGridEditComponent<PatientViewModel> implements OnInit {
  @Input() patient?: PatientViewModel[];  // Overrides API call

  listName = 'Patient';
  initialSortField = PATIENT_DISPLAY_FIELD;
  itemFields = listFields;

  selectedItem$ = this.speciesFacade.selected$;
  selectedItem: SpeciesViewModel | any;
  selectedItemName?: string = '';

  displayValueFns: KeyValuePairs<Function> = {};

  diagnosisFilter?: string;
  topographyFilter?: string;

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,
    private router: Router,

    private speciesFacade: SpeciesFacade,
    private breedService: BreedService,
    private dataSourceService: DataSourceService,
    private speciesService: SpeciesService,

    service: PatientService,
    facade: PatientFacade,
  ) {
    super(route, facade, { service }, { loadOverride: true, route }); // Prevents full load on init

    COMPONENT_THIS = this; // For use in CreateNewFormGroupItem called from BaseGridEditComponent
    this.showSingleId = route.snapshot.params?.['id'];

    this.subscriptions.push(this.dataSourceService.getAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<DataSourceViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === DATA_SOURCE_DDL_FIELD)!.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, pagedResults.results, DATA_SOURCE_KEY_FIELD);
    }));

    this.subscriptions.push(this.speciesService.getAll({ sort: SPECIES_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<SpeciesViewModel>) => {
      this.itemFields.find((i: GridEditorField) => i.field === SPECIES_DDL_FIELD)!.options = CreateSelectOptionsFromList(SPECIES_DISPLAY_FIELD, pagedResults.results, SPECIES_KEY_FIELD);
    }));
  }

  override ngOnInit(): void {
    if (this.patient) {
      this.showSingleId = this.patient[0].id;
    }
    if (this.gridEditorPageState.extraFilters?.diagnosisFilter || this.gridEditorPageState.extraFilters?.topographyFilter) {
      if (this.gridEditorPageState.extraFilters?.diagnosisFilter) {
        this.diagnosisFilter = this.gridEditorPageState.extraFilters.diagnosisFilter;
      }
      if (this.gridEditorPageState.extraFilters?.topographyFilter) {
        this.topographyFilter = this.gridEditorPageState.extraFilters.topographyFilter;
      }
      this.onApplyFilters();
    }
  }

  override onViewChange(event: ViewChangeEvent): void {
    // Reset the grid breeds options based on species in-case thay were changed in the detail view
    if (event.view === 'grid') {
      this.setBreedOptionsForSpecies(this.selectedItem[SPECIES_KEY_FIELD]);
    }
    super.onViewChange(event);
  }

  override CreateNewFormGroupItem(itemData: PatientViewModel = {}, fields?: GridEditorField[] | undefined): FormGroup<any> {
    // Set some defaults for a new item
    if (!itemData.id) {
      itemData.species_id = COMPONENT_THIS.selectedItem.id;
      itemData.desexed = false;
    }

    const itemFG =  super.CreateNewFormGroupItem(itemData);

    // Set breed options first time
    COMPONENT_THIS.setBreedOptionsForSpecies(itemData.species_id, itemFG);
    // Hook species changes
    COMPONENT_THIS.subscriptions.push(itemFG.get(SPECIES_DDL_FIELD)!.valueChanges.subscribe((speciesId: string) => {
      COMPONENT_THIS.setBreedOptionsForSpecies(speciesId, itemFG);
    }));

    return itemFG;
  }

  speciesChanged(species: SpeciesViewModel): void {
    if (!species) { return; }
    this.selectedItem = species;
    this.selectedItemName = species[SPECIES_DISPLAY_FIELD];
    this.loadOverride = false;
    this.setBreedOptionsForSpecies(species[SPECIES_KEY_FIELD]);
    super.loadPage();
  }

  override preloadCallback(): void {
    if (!this.selectedItem) { return; }  // Wait until the Patient has been selected

    this.loadOverride = false;

    // Filter by breed
    this.addFilter({ field: RT.SPECIES, matchMode: FilterMatchMode.equals, value: [this.selectedItemName] });

    // TODO: Remove after testing
    // this.addFilter({ field: 'patient_code', matchMode: FilterMatchMode.contains, value: ['21/0268'] });
  }

  customAction(event: {actionName: string, data: PatientViewModel} | any, formGroup?: FormGroup) {
    switch (event.actionName) {
      case SHOW_CASES_ACTION.actionName:
        this.router.navigate([CaseConstants.UI.ROOT_PATH], { state: this.AsStickyFilterState(MapFiltersToPageFilters({patient_id: event.data[PATIENT_KEY_FIELD] }), 'Return to Patients' ) });
        break;

      default:
        break;
    }
  }

  setBreedOptionsForSpecies(speciesId: string | null | undefined, patientFG?: FormGroup): void {
    // Change the breed and cross-breed options to only be of that species
    COMPONENT_THIS.breedService.getAll({ sort: BREED_DISPLAY_FIELD, pageSize: -1, endpoint: buildPath(RT.SPECIES, speciesId)})
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe((pagedResults: PagedResult<BreedShallowViewModel>) => {
        [BREED_DDL_FIELD, CROSS_BREED_DDL_FIELD].forEach(breedFldName => {
          // Set options
          listFields.find((i: GridEditorField) => i.field === breedFldName)!.options = CreateSelectOptionsFromList(BREED_DISPLAY_FIELD, pagedResults.results, BREED_KEY_FIELD);

          if (patientFG) {
            // Choose option by value
            const breedFld = patientFG.get(breedFldName)!;
            // Clear value if not in list
            if (pagedResults.results.findIndex(b => b.id === breedFld.value) < 0) {
              breedFld.setValue(null);
            }
            // Display value after options set
            breedFld.updateValueAndValidity();
          }
        });
      });
  }

  onApplyFilters(): void {

    safeDelete(this.currentPage, 'urlQueryString');
    safeDelete(this.currentPage, 'endPoint');
    const currState = safeObject(this.gridEditorPageState);
    safeDelete(currState, 'extraFilters');
    this.gridEditorPageState = {...currState};

    if (this.diagnosisFilter || this.topographyFilter) {
      const extraFilters: any = {};
      if (this.diagnosisFilter) {
        extraFilters.diagnosisFilter = this.diagnosisFilter;
      }
      if (this.topographyFilter) {
        extraFilters.topographyFilter = this.topographyFilter;
      }

      this.currentPage = { ...safeObject(this.currentPage), urlQueryString: extraFilters, endpoint: RT.WITH_FILTERS};
      this.gridEditorPageState = {...safeObject(this.gridEditorPageState), extraFilters};
    }
    super.loadPage();
  }
}
// console.log(`%cPatientGridEditComponent::ngOnInit`, 'background:yellow');
