import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
import HC_more from "highcharts/highcharts-more";

import { objectToQuerystring } from '@identic/api';
import { BaseSubscriptions, buildPath, deepCopy, formatDate, safeObject, setSafeValue, toDate } from '@identic/core';
import { ReportConstants, ReportService } from 'dashboard/data-access';
import { DataSourceViewModel } from 'data-source/data-access';
import { ChartOptionsModel } from '../models';
import { Required } from '../utils';
import { ReportChartSelectionService } from './report-chart-selection.service';

HC_map(Highcharts); // Needed to render maps
HC_more(Highcharts);

// R(oute)T(oken)
const RT = ReportConstants;

@Component({
  selector: 'report-chart',
  template: `
    <ng-content><!-- Title goes here if provided --></ng-content>

    <ng-container *ngIf="(graphData$ | async); let graphData">
        <highcharts-chart class='default-graph'
              [Highcharts]="Highcharts"
              [constructorType]="chartOptions.constructorType??'chart'"
              [options]="graphData | chartOptions:seriesName:chartOptions"
              (click)="graphClicked($event)"
              (chartRendered)="onChartRendered($event)"
        >
        </highcharts-chart>
    </ng-container>

    <ng-content select=".chart-footer"></ng-content>
`,
  styles: [`
  .default-graph { display: block; }  /* Full col container width */
  .aus-map-graph { height: 40vh; }
  .pie-graph { height: 10vh; }
`],
})
export class ReportChartComponent extends BaseSubscriptions implements OnInit, OnChanges {
  @Input() @Required id!: string;
  @Input() @Required chartOptions!: ChartOptionsModel;
  @Input() @Required species!: string | null;  //'canine' | 'feline';
  @Input() @Required reportRoute!: string;
  @Input() startDate?: Date;
  @Input() endDate?: Date;
  @Input() graphHeight: string | number | undefined = undefined;
  @Input() dataSource: DataSourceViewModel | null = null;
  @Input() isPublic: boolean = false;
  @Input() topCount: number = -1;
  @Input() seriesName: string = 'Cases Reported';
  @Output() onClick = new EventEmitter<MouseEvent>();
  @Output() chartRendered = new EventEmitter<any>();

  graphData$: Observable<any> | undefined;
  graphData: any;

  Highcharts = Highcharts;  // For use in template
  chartInstance: Highcharts.Chart | undefined;

  constructor(
    private selectionService: ReportChartSelectionService,
    private service: ReportService,
  ) {
    super();  // For BaseSubscriptions
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.startDate = toDate(this.startDate);
    this.endDate = toDate(this.endDate);
    this.updateData();
  }

  ngOnInit(): void {
    if (this.graphHeight) {
      setSafeValue(this.chartOptions, 'chartOptions.chart.height', this.graphHeight);
    }

    setSafeValue(this.chartOptions, 'chartOptions.chart.events.render', (event:any) => {
      this.onChartRendered(event);
    });

    // // Monitor changes in selected points
    this.subscriptions.push(this.selectionService.selectedPoints$.subscribe(() => {
      this.updateData();
    }));
  }

  onChartRendered(event: any): void {
    this.chartInstance = event.target;
    this.highlightSelectedPoints();
  }

  updateData(): void {
      const restful = buildPath(RT.SPECIES, this.species, this.reportRoute);
      const filters = deepCopy(safeObject(this.selectionService.getAllSelections()));

      if (this.dataSource?.id) {
        filters[RT.DATA_SOURCE] = this.dataSource?.id;
      }
      if (!this.isPublic) {
        delete filters[RT.REGION];
      }
      if (this.topCount > 0) {
        filters[RT.TOP] = this.topCount
      }
      if (this.startDate) {
        filters[RT.START] = formatDate(this.startDate);
      }
      if (this.endDate) {
        filters[RT.END] = formatDate(this.endDate);
      }
      this.graphData$ = this.service.getReportData(restful+objectToQuerystring(filters));
  }

  highlightSelectedPoints(): void {
    if (!this.chartInstance) return;

    if (!this.selectionService.selectionsExist(this.id)) return;

    this.chartInstance.series.forEach(series => {
      series.data.forEach(dataPoint => {
        if (this.selectionService.isSelected(this.id, dataPoint.name)) {
          dataPoint.select(true, true);
        }
      });
    });
  }

  graphClicked(event: MouseEvent | any): void {
    if (event.point) {
      // 'event' contains data about the clicked point.
      // Update the selection in the service using 'id' as the unique key.
      if (event.point.selected) {
        this.selectionService.selectPoint(this.id, event.point.name);
      } else {
        this.selectionService.deselectPoint(this.id);
      }
    }
    this.onClick.emit(event);
  }
}
// console.log(`%cReportChartComponent::`, 'background:yellow');
