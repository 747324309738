import { ChartOptionsModel } from '../models';
import { BaseChartOptions } from './base-chart.options';
import { defaultPieChartOptions } from './pie-chart.options';

const chartOptions: Highcharts.Options = {...defaultPieChartOptions.chartOptions,
    chart: {...defaultPieChartOptions.chartOptions.chart,
      ...BaseChartOptions.chart // To get a unique render event for donutChart
    }
  // chart: {
  //   className: 'donut-graph',
  // },
} as const;

const seriesOptions: Highcharts.PlotPieOptions = {
  ...<Highcharts.PlotPieOptions>defaultPieChartOptions.seriesOptions,
  // color: '#a5d6a7',
  // borderColor: '#60A465',
  innerSize: '60%',
  size: '75%',
  dataLabels: {
    // style: { color: ;},
    // connectorColor: 'grey',
    // connectorWidth: 1,
    // zIndex: 2,
    // distance: -50
  }
} as const;

export const defaultDonutChartOptions: ChartOptionsModel = {
  chartOptions,
  seriesOptions,
}
