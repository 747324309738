import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplateDisplayModule } from '@identic/templates';
import { CustomControlsModule } from '@identic/controls';
import { DataSourceSelectorModule } from 'data-source/features';
import { DiagnosisEditorModule } from 'diagnosis/features';
import { DashboardEditorComponent } from './editor.component';
import { DashboardSharedModule } from '../shared';

const COMPONENTS = [DashboardEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    DataSourceSelectorModule,
    TemplateDisplayModule,
    DashboardSharedModule,
    DiagnosisEditorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DashboardEditorModule {}
// NOTE: Need this in a module separate to the DashboardModule so that it can be loaded without the guards on the other routes being activated causing a loop
