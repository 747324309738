import { GridEditorField, ControlType } from '@identic/controls';

export const SAMPLE_CASE_DDL_FIELD = 'case_id';
export const SAMPLE_CERTAINTY_LEVEL_DDL_FIELD = 'certainty_level_id';
export const SAMPLE_DIAGNOSIS_FIELD = 'diagnosis';
export const SAMPLE_DIAGNOSIS_AC_FIELD = 'diagnosis_id';
export const SAMPLE_GRADE_DDL_FIELD = 'grade_id';
export const SAMPLE_HUMAN_TOUCHED_BOOL_FIELD = 'human_touched';
export const SAMPLE_NAME_FIELD = 'sample_name';
export const SAMPLE_TOPOGRAPHY_METASTASIS_CHIP_FIELD = 'topography_metastasis_id';
export const SAMPLE_TOPOGRAPHY_PRIMARY_AC_FIELD = 'topography_primary_id';
export const SAMPLE_TOPOGRAPHY_PRIMARY_FIELD = 'topography_primary';
export const SAMPLE_METASTASES_FIELD = 'sample_metastases';

export const listFields: GridEditorField[] = [
  {
    field: SAMPLE_NAME_FIELD, header: 'Name', required: true,
    gridClass: 'col-md-1', gridEdit: false,
    detailRow: 0, detailClass: 'col-md-4 ps-3',
  },
  { field: SAMPLE_DIAGNOSIS_AC_FIELD, header: 'Diagnosis Id', gridHide: true, detailHide: true },
  { // type: Lookup
    field: SAMPLE_DIAGNOSIS_FIELD, header: 'Diagnosis', required: true,
    gridClass: 'col-md-2 grid-x-small-text',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.autocomplete,
    service: 'diagnosisService',  // Needed to display
    data: {
      autocomplete: {
        idTargetFieldname: SAMPLE_DIAGNOSIS_AC_FIELD,
      }
    }
  },
  { field: SAMPLE_TOPOGRAPHY_PRIMARY_AC_FIELD, header: 'Primary Site Id', gridHide: true, detailHide: true },
  { // type: Lookup
    field: SAMPLE_TOPOGRAPHY_PRIMARY_FIELD, header: 'Primary Site', required: true,
    gridClass: 'col-md-2 grid-x-small-text',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.autocomplete,
    service: 'topographyService',  // Needed to display
    data: {
      autocomplete: {
        idTargetFieldname: SAMPLE_TOPOGRAPHY_PRIMARY_AC_FIELD,
      }
    }
  },
  { field: SAMPLE_TOPOGRAPHY_METASTASIS_CHIP_FIELD, header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: SAMPLE_METASTASES_FIELD, header: 'Metastasis',
    gridClass: 'col-md-3',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.autochips, //filterType: ControlType.dropdown,
    service: 'sampleMetastasisService',  // Needed to display
    data: {
      showDel: { class: 'grid-header-row' },
      autocomplete: { idTargetFieldname: SAMPLE_TOPOGRAPHY_METASTASIS_CHIP_FIELD },
    }
  },
  { field: 'grade', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: SAMPLE_GRADE_DDL_FIELD, header: 'Grade',
    gridClass: 'col-md-1 grid-x-small-text',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: 'certainty_level', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: SAMPLE_CERTAINTY_LEVEL_DDL_FIELD, header: 'Certainty',
    gridClass: 'col-md-1 grid-x-small-text',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { // type: boolean
    field: SAMPLE_HUMAN_TOUCHED_BOOL_FIELD, header: '',
    gridClass: 'col-md-1 text-center text-success', gridEdit: false,
    detailHide: true,
    controlType: ControlType.boolean,
    data: {
      on: { icon: 'person' },
      off: { icon: 'memory' },
    },
  },
  { field: SAMPLE_CASE_DDL_FIELD, header: '', gridHide: true, detailHide: true },
  { field: 'date_added', header: '', gridHide: true, detailHide: true },
  { field: 'notes', header: '', gridHide: true, detailHide: true },
  { field: '_case', header: '', gridHide: true, detailHide: true },
  { field: 'raw_data', header: '', gridHide: true, detailHide: true },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-1',
    detailHide: true,
    actions: {
      showAdd: { class: 'grid-header-row' },
      showDel: { class: 'grid-header-row' },
      showEdit: false,
      showView: false,
      showReload: { class: 'grid-header-row' },
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
