export const DATA_SOURCE_KEY_FIELD = 'id';
export const DATA_SOURCE_DISPLAY_FIELD = 'name';

/* For gen-fields
export interface DataSourceViewModel {
  id?: string | null;
  data_name?: string;
  name?: string;
  contact?: string;
  country_code?: string;
  email?: string;
  phone?: string;
  url?: string;
}
*/
export interface DataSourceShallowViewModel {
  id?: string | null;
  data_name?: string;
  name?: string;
}

export interface DataSourceViewModel extends DataSourceShallowViewModel {
  // id?: string | null;
  // data_name?: string;
  // name?: string;
  contact?: string;
  country_code?: string;
  email?: string;
  phone?: string;
  url?: string;
}

// export interface DataSourceModel {
//   *** not in VM ***
//   cases: CaseModel[];
//   patients: PatientModel[];
// }
