import { Pipe, PipeTransform } from '@angular/core';
import { ReportConstants } from 'dashboard/data-access';

// R(oute)T(oken)
const RT = ReportConstants;

@Pipe({
  name: 'formatDashboardHeader',
  pure: true
})
export class FormatDashboardHeaderPipe implements PipeTransform {

  transform(dashboardHeader: string | null | undefined, species: string): any {
    // Line break for all BUT Australia
    if (!(dashboardHeader??'').startsWith(RT.ALL_OF_AUSTRALASIA)) {
      dashboardHeader += '<br/>';
    } else {
      species = species.toLowerCase();
    }
    return `${dashboardHeader} ${species}`;
  }
}
// console.log(`%cFormatDashboardHeaderPipe::transform`, 'background:yellow');
