import { BaseChartOptions } from './base-chart.options';
import { ChartOptionsModel } from '../models';
import { ReportConstants } from 'dashboard/data-access';

const chartOptions: Highcharts.Options = {
  ...BaseChartOptions,
  chart : {
    className: 'bubble-graph',
    type: 'packedbubble',
  },
  tooltip : {
    useHTML: true,
    pointFormat: '<b>{point.name}:</b> {point.value:.1f}%'
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    packedbubble: {
      minSize: '60%',
      maxSize: '120%',
      dataLabels: {
        enabled: true,
        // format: '{point.name}',
        format: '{point.name}<br/>{point.value:.1f}%',
        style: {
          color: 'black',
          textOutline: 'none',
          fontSize: 'larger',
          fontWeight: 'bold'
        }
      }
    }
  },
 };

 const seriesOptions: Highcharts.PlotBubbleOptions = {
  // color: '#a5d6a7',
  // borderColor: '#60A465',
} as const;

export const defaultBubbleChartOptions: ChartOptionsModel = {
  chartOptions,
  seriesOptions,
}
