import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { SpeciesDataAccessModule } from 'species/data-access';
import { SpeciesSelectorModule,  SpeciesSelectorComponent, SPECIES_SELECTOR_PATH } from './selector';
import { SpeciesEditorComponent } from './editor';

const COMPONENTS = [SpeciesEditorComponent];

export const routerModuleForChild = RouterModule.forChild([
  { path: SPECIES_SELECTOR_PATH, component: SpeciesSelectorComponent },
  { path: '', component: SpeciesEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),

    SpeciesDataAccessModule,
    SpeciesSelectorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SpeciesModule {}
