import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { safeValue, ConfigService, buildPath } from '@identic/core';
import { BaseService } from '@identic/api';
import { CaseViewModel, CASE_DISPLAY_FIELD } from './+state';
import { CaseConstants } from './data-access.constants';

// R(oute)T(oken)
const RT = CaseConstants;

@Injectable({providedIn: 'root'})
export class CaseService extends BaseService<CaseViewModel> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, buildPath(config.environment.API_BASE_URL, safeValue(config, 'environment.Api.Case', RT.defaultApi)), CASE_DISPLAY_FIELD);
  }
}
