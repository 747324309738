import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { CaseDataAccessModule } from 'case/data-access';
import { SampleEditorModule } from 'sample/features';
import { SampleDataAccessModule } from 'sample/data-access';
import { CaseEditorComponent } from './editor.component';

const COMPONENTS = [CaseEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    CaseDataAccessModule,
    SampleDataAccessModule,
    SampleEditorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class CaseEditorModule {}
