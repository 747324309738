import { BaseChartOptions } from './base-chart.options';
import { ChartOptionsModel } from '../models';
import { ReportConstants } from 'dashboard/data-access';

const chartOptions: Highcharts.Options = {
  ...BaseChartOptions,
  chart : {
    className: 'line-graph',
    type: 'line',
  },
  xAxis: {
    type: 'category',
    labels: {
      useHTML: true,
      reserveSpace: true,
    },
  },
  yAxis: {
    title: { text: '# Cases' }
  },
  title : { text: undefined },
  credits: { enabled: false },
  tooltip : {
    enabled: true,
    pointFormat: '{point.y}'
  },
  plotOptions : {
    bar: {
      dataLabels: {
        enabled: false
      },
    }
  },
  legend: { enabled: false },
} as const;

const seriesOptions: Highcharts.PlotLineOptions = {
  // color: '#a5d6a7',
  // borderColor: '#60A465',
} as const;

export const defaultLineChartOptions: ChartOptionsModel = {
  chartOptions,
  seriesOptions,
}
