import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserSubscriptionService } from './data-access.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    UserSubscriptionService,
  ],
})
export class UserSubscriptionDataAccessModule {}
