import { GridEditorField, ControlType, FilterMatchMode } from '@identic/controls';
import { GET_EXCEL_FILE_FROM_ARCHIVE_ACTION } from '../../data-access/data-access.constants';

export const AGE_FIELD = 'age';
export const DATA_SOURCE_DDL_FIELD = 'data_source_id';
export const SAMPLE_COUNT_FIELD = '_sample_count_';
export const SPECIES_FIELD = '_species_';
export const PATIENT_AC_FIELD = 'patient_id';
export const PATIENT_FIELD = 'patient';

export const listFields: GridEditorField[] = [
  {
    field: DATA_SOURCE_DDL_FIELD, header: 'Data Source', required: true, readOnly: true,
    sortable: true, filterMatchMode: FilterMatchMode.equals,
    gridClass: 'col-md-2',
    detailRow: 0, detailClass: 'col-md-3',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  {
    field: 'reference_code', header: 'Reference', required: true,
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 1, detailClass: 'col-md-3',
  },
  { // type: Date
    field: 'consultation_date', header: 'Consultation',
    sortable: true, filterMatchMode: FilterMatchMode.between,
    gridClass: 'col-md-1',
    detailRow: 1, detailClass: 'col-md-2',
    controlType: ControlType.calendar, filterType: ControlType.calendar,
    data: { gridDateFormat: 'd MMM y', dateFormat: 'd MMM y h:mm a' },
  },
  {
    field: SPECIES_FIELD, header: 'Species',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailHide: true,
    filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: PATIENT_AC_FIELD, header: 'Patient Id', gridHide: true, detailHide: true },
  { // type: Lookup
    field: PATIENT_FIELD, header: 'Patient', required: true,
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-3',
    controlType: ControlType.autocomplete,
    service: 'patientService',  // Needed to display
    data: {
      autocomplete: {
        idTargetFieldname: PATIENT_AC_FIELD,
      }
    }
  },
  { // type: number
    field: AGE_FIELD, header: 'Age',
    sortable: true,
    gridClass: 'col-md-1',
    detailRow: 1, detailClass: 'col-md-1',
    matHint: '(days)',
  },
  { field: 'date_added', header: '', gridHide: true, detailHide: true },
  {
    field: 'source_filename', header: 'Source',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 2, detailClass: 'col-md-6',
  },
  { // type: number
    field: 'source_row', header: 'Row',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 2, detailClass: 'col-md-2',
  },
  {
    field: 'report_data', header: 'Original report data',
    gridHide: true,
    detailRow: 3, detailClass: 'col-md-12 mt-3', detailEdit: false,
    controlType: ControlType.html,
    data: { minToolbar: true }
  },
  {
    field: SAMPLE_COUNT_FIELD, header: 'Samples',
    gridClass: 'col-md-1',
    detailHide: true,
  },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-2',
    detailHide: true,
    actions: {
      showAdd: { class: 'grid-header-row' },
      showDel: { class: 'grid-header-row' },
      showEdit: { class: 'grid-header-row' },
      showView: false,
      showReload: { class: 'grid-header-row' },
      detailAction: [
        GET_EXCEL_FILE_FROM_ARCHIVE_ACTION,
      ]
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
