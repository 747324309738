import { ReportConstants } from "dashboard/data-access";
import { defaultDonutChartOptions } from "./donut-chart.options";
import { defaultPieChartOptions } from "./pie-chart.options";
import { defaultAusMapChartOptions } from "./aus-map-chart.options";
import { defaultBarChartOptions } from "./bar-chart.options";
import { defaultBubbleChartOptions } from "./bubble-chart.options";
import { defaultColumnChartOptions } from "./column-chart.options";
import { defaultLineChartOptions } from "./line-chart.options";

export const diagnosisChartOptions = defaultDonutChartOptions;

export const topographyChartOptions = defaultPieChartOptions;

export const ausMapChartOptions = {...defaultAusMapChartOptions,
  chartOptions: {...defaultAusMapChartOptions.chartOptions,
    legend: {enabled: false}
  },
  seriesOptions: {...defaultAusMapChartOptions.seriesOptions,
    allowPointSelect: true,
    color: ReportConstants.ACARCINOM_BROWN,
    states: {
      select:{ color: ReportConstants.ACARCINOM_YELLOW }
    },
  }
}

export const breedChartOptions = {...defaultBarChartOptions,
  seriesOptions: {...defaultBarChartOptions.seriesOptions,
    allowPointSelect: true,
    cursor: 'pointer',
    states: {
      select: { color: '#95CEFF' }
    },
    color: ReportConstants.ACARCINOM_YELLOW
  }
}

export const genderChartOptions = {...defaultBubbleChartOptions,
  seriesOptions: {...defaultBarChartOptions.seriesOptions,
    color: ReportConstants.ACARCINOM_YELLOW
  }
}

export const ageChartOptions = {...defaultColumnChartOptions,
  seriesOptions: {...defaultBarChartOptions.seriesOptions,
    color: ReportConstants.ACARCINOM_YELLOW
  }
}

export const trendChartOptions = {...defaultLineChartOptions,
  seriesOptions: {...defaultBarChartOptions.seriesOptions,
    color: ReportConstants.ACARCINOM_YELLOW
  }
}
