<grid-editor
  [dataReady]="(loaded$ | async) && (allDropdownsLoaded$ | async)" [dataList]="list$ | async"
  [firstRecord]="firstRecord$ | async" [totalRecords]="totalRecords$ | async" [rows]="currentPage.pageSize"
  [itemFields]="itemFields" [services]="services" [updateOnly]="updateOnly"
  [listName]="listName" listNamePlural="Records waiting for import" [initialSortField]="initialSortField" [backButton]="backButton" [showSingleId]="showSingleId"
  [(currentView)]="currentView"
  [CreateNewFormGroupItemFn]="CreateNewFormGroupItem" tokensCol="available_tokens"
  [listIcon]="(route.data | async)!['icon']" [listIconAwesome]="(route.data | async)!['faIcon']"
  [getDisplayValueFns]="displayValueFns"
  [getDisplayClassFns]="displayClassFns"
  [readOnly]="true"
  [showDetailPrevNextButtons]="true"
  [showDetailActionButtons]="false"

  (viewChange)="onViewChange($event)"
  (pageChange)="onPageChange($event)"
  (create)="onCreate($event)"
  (update)="onUpdate($event)"
  (delete)="onDelete($event)"
  (reload)="onReload($event)"
  (select)="onSelectItems($event)"
  (unselect)="onUnselectItems($event)"
  (customAction)="onCustomAction($event)">

  <ng-template #DetailTemplate let-detailItemFG="detailItemFG" let-detailItemIndex="self.detailItemIndex" let-dataList="self.dataList" let-base="self">
    <ng-container *ngIf="caseFG">
      <form [formGroup]="caseFG!">
        <div class="container-fluid">
          <div class="row">
            <div class="col-9">
              <div class="row">
                <div class="col-12">
                  <h3>Review individual import row data</h3>
                </div>
              </div>

              <div class="row">
                <ng-container *ngFor="let col of listFields" >
                  <form-group-control *ngIf="!col.detailHide" [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                      [col]="col" [maxLen]="col.detailMaxLen"
                      [readOnly]="col.detailEdit === false" [service]="service"
                      [dataItem]="detailItemFG"
                      (changed)="needsSaving = true">

                    <span *ngIf="col.field === SOURCE_FILENAME_FIELD" class="form-group-control-content-suffix">
                      <a (click)="onDownloadOriginalExcelFile(detailItemFG, col.field)"
                          title="Download original Excel file"
                          href="#" onClick="return false;/* Show correct pointer but leave action to Angular (click)*/"
                          class="ms-2">
                          <i class="text-success bg-white fas fa-file-arrow-down" aria-hidden="true"></i>
                      </a>
                    </span>

                </form-group-control>
              </ng-container>
              </div>

              <div class="row my-1 py-1 rounded text-bg-primary">
                <div class="col-12">
                  Patient
                </div>
              </div>

              <div *ngFor="let rowNumber of (patientFields | getRowNumbers)" class="row">
                <ng-container *ngFor="let col of (patientFields | getColsByRow:rowNumber)" >
                  <form-group-control [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                      [col]="col" [maxLen]="col.detailMaxLen"
                      [readOnly]="col.detailEdit === false" [service]="service"
                      [dataItem]="patientFG!"
                      (changed)="needsSaving = true">
                  </form-group-control>
                </ng-container>
              </div>

              <div class="row my-1 py-1 rounded text-bg-primary">
                <div class="col-12">
                  Case
                </div>
              </div>
              <div *ngFor="let rowNumber of (caseFields | getRowNumbers)" class="row">
                <ng-container *ngFor="let col of (caseFields | getColsByRow:rowNumber)" >
                  <div *ngIf="col.field === REPORT_DATA_FIELD" class="col-12 my-1 py-1 rounded text-bg-primary d-flex justify-content-between">
                    <div>Report Text (automated analysis)</div>
                    <div>
                      <button mat-raised-button title="Original Excel data" class="mat-accent ps-0 pe-2 h-100 mb-1"
                            (click)="onPopupOriginalExcelData(detailItemFG, col.field)">
                        <mat-icon>assignment</mat-icon>Source
                      </button>
                    </div>
                  </div>
                  <form-group-control [class]="col.detailClass" currentView="detail" [class.text-truncate]="col.detailTrunc"
                      [col]="col" [maxLen]="col.detailMaxLen"
                      [readOnly]="col.detailEdit === false" [service]="service"
                      [dataItem]="caseFG!"
                      (changed)="needsSaving = true">
                  </form-group-control>
                </ng-container>
              </div>
            </div>
            <div class="col-3" *ngIf="detailItemFG | getJsonMessagesTypes; let importJsonMessageTypes">
              <json-message *ngIf="importJsonMessageTypes.parser_messages" [messages]="detailItemFG | getJsonMessages:'parser_messages'" [bothMessageTypes]="importJsonMessageTypes.parser_messages && (importJsonMessageTypes.commit_messages || !!detailItemFG.get('commit_messages').value)">
                <strong>Parser Messages</strong>
              </json-message>
              <json-message *ngIf="importJsonMessageTypes.commit_messages || !!detailItemFG.get('commit_messages').value" [messages]="ensureJson(detailItemFG.get('commit_messages').value)" [bothMessageTypes]="importJsonMessageTypes.parser_messages && (importJsonMessageTypes.commit_messages || !!detailItemFG.get('commit_messages').value)">
                <strong>Commit Messages</strong>
              </json-message>
            </div>
          </div>
          <div class="row">
            <div *ngIf="(caseFG!.getRawValue().samples??[]); let samples" class="col-12 my-2 samples">
              <sample-grid-edit
                  [samples]="samples"
                  [isComponentOnPage]="true"

                  (statusChange)="onStatusChangeSample($event)"
                  (viewChange)="onViewChangeSample($event)"
                  (create)="onUpdateSample(caseFG!, $event)"
                  (update)="onUpdateSample(caseFG!, $event)"
                  (delete)="onDeleteSample(caseFG!, $event)">
              </sample-grid-edit>
            </div>
          </div>
          <div class="row">
            <div *ngIf="!addingSample" class="col-md-8 offset-md-2 d-flex justify-content-around">
              <button mat-raised-button class="text-bg-primary" title="Back to list"
                  (click)="onCloseDetailItem(detailItemFG, caseFG!)">
                <mat-icon>first_page</mat-icon>Back to list
              </button>
              <button mat-raised-button color="primary" title="Previous"
                  (click)="base.prevDetailItem()"
                  [disabled]="(detailItemIndex <= 0) || needsSaving">
                <mat-icon>chevron_left</mat-icon>Previous
              </button>
              <button mat-raised-button color="primary" title="Next"
                  (click)="base.nextDetailItem()"
                  [disabled]="(detailItemIndex >= dataList.length-1) || needsSaving">
                <mat-icon>chevron_right</mat-icon>Next
              </button>
              <button [class.save-btn-invalid]="samplesInvalid || detailItemFG?.invalid" mat-raised-button [class.text-bg-success]="!(samplesInvalid || detailItemFG?.invalid)" [title]="((samplesInvalid || detailItemFG?.invalid) ? 'Import data is invalid' : 'Commit')"
                  (click)="onCommitItem(detailItemFG, caseFG!, base)">
                <mat-icon>cloud_upload</mat-icon>Commit
              </button>
              <button mat-raised-button class="text-bg-primary" title="Save"
                  (click)="onSaveItem(detailItemFG, caseFG!)">
                <mat-icon>save</mat-icon>Save
              </button>
              <button mat-raised-button color="warn" title="Delete"
                  (click)="onDeleteItem(detailItemFG, base)">
                <mat-icon>delete</mat-icon>Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-template #UploadTemplate>
      <upload-excel-file [allDataSources]="true"></upload-excel-file>
      <mat-card-actions align="start">
        <button mat-raised-button class="text-bg-primary" title="Back" (click)="returnToGrid(detailItemFG)"><mat-icon>chevron_left</mat-icon>Back</button>
      </mat-card-actions>
    </ng-template>

  </ng-template>
</grid-editor>
