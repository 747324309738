import { GridEditorField, ControlType, FilterMatchMode, CreateSelectOptionsFromEnum } from '@identic/controls';
import { SHOW_CASES_ACTION } from 'case/data-access';
import { Sex } from 'patient/data-access';

export const BREED_DDL_FIELD = 'breed_id';
export const CROSS_BREED_DDL_FIELD = 'cross_breed_id';
export const DATA_SOURCE_DDL_FIELD = 'data_source_id';
export const SPECIES_DDL_FIELD = 'species_id';
export const SPECIES_FIELD = 'species';

export const listFields: GridEditorField[] = [
  { // type: Lookup
    field: DATA_SOURCE_DDL_FIELD, header: 'Data Source', required: true,
    sortable: true, filterMatchMode: FilterMatchMode.equals,
    gridClass: 'col-md-2',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { // type: Date
    field: 'date_added', header: 'Date Added',
    sortable: true, filterMatchMode: FilterMatchMode.between,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.calendar,
    data: { gridDateFormat: 'd MMM y', dateFormat: 'd MMM y h:mm a' },
  },
  { field: SPECIES_FIELD, header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: SPECIES_DDL_FIELD, header: 'Species', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: 'breed', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: BREED_DDL_FIELD, header: 'Breed', required: true,
    sortable: true, filterMatchMode: FilterMatchMode.equals,
    gridClass: 'col-md-2',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  { field: 'cross_breed', header: '', gridHide: true, detailHide: true },
  { // type: Lookup
    field: CROSS_BREED_DDL_FIELD, header: 'CrossBreed',
    sortable: true, filterMatchMode: FilterMatchMode.equals,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.dropdown, filterType: ControlType.dropdown,
    options: [],  // Filled by Component()
  },
  {
    field: 'patient_code', header: 'PatientCode', required: true,
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'sex', header: 'Sex',
    sortable: true, filterMatchMode: FilterMatchMode.equals, required: true,
    gridClass: 'col-md-1',
    detailRow: 1, detailClass: 'col-md-4', detailEdit: true,
    controlType: ControlType.dropdown,
    filterType: ControlType.dropdown,
    options: CreateSelectOptionsFromEnum(Sex),
    // options: CreateTextSelectOptionsFromEnum(Sex),
  },
  { // type: boolean
    field: 'desexed', header: 'Desexed', required: true,
    sortable: true, filterMatchMode: FilterMatchMode.equals,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.boolean,
  },
  { // type: number
    field: 'postcode', header: 'Postcode',
    sortable: true, filterMatchMode: FilterMatchMode.contains,
    gridClass: 'col-md-1',
    detailRow: 0, detailClass: 'col-md-4',
  },
  {
    field: 'dob', header: 'Dob',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-4',
    controlType: ControlType.calendar,
    data: { gridDateFormat: 'd MMM y', dateFormat: 'd MMM y', calendarType: 'date' },
  },

  {
    field: 'actions', header: '',
    gridClass: 'col-md-2',
    detailHide: true,
    actions: {
      showAdd: { class: 'grid-header-row' },
      showDel: { class: 'grid-header-row' },
      showEdit: { class: 'grid-header-row' },
      showView: false,
      showReload: { class: 'grid-header-row' },
      detailAction: [
        SHOW_CASES_ACTION,
      ],
    },
    controlType: ControlType.custom
  },
  { field: 'id', header: 'Id', gridHide: true, detailHide: true },
];
