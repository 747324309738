import { ControlType, GridEditorField } from "@identic/controls";

export const DATE_RANGE_START_FIELD = 'start_date';
export const DATE_RANGE_END_FIELD = 'end_date';

export const dateRangePopupFields: GridEditorField[] = [
  {
    field: DATE_RANGE_START_FIELD, header: 'Start Date', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-5',
    controlType: ControlType.calendar,
    matHint: `<small>last 12 months by default</small>`,
  },
  {
    field: '_separator_', header: '',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-2 text-center mt-3', detailEdit: false,
    controlType: ControlType.template,
    data: { template: 'to' }
  },
  {
    field: DATE_RANGE_END_FIELD, header: 'End Date', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-5',
    controlType: ControlType.calendar,
  },
];

export const monthlyTrendFields: GridEditorField[] = [
  {
    field: DATE_RANGE_START_FIELD, header: 'Start Date', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-5',
    controlType: ControlType.calendar,
    matHint: `<small>last 12 months by default</small>`,
  },
  {
    field: '_separator_', header: '',
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-2 text-center mt-3', detailEdit: false,
    controlType: ControlType.template,
    data: { template: 'to' }
  },
  {
    field: DATE_RANGE_END_FIELD, header: 'End Date', required: true,
    gridHide: true,
    detailRow: 0, detailClass: 'col-md-5',
    controlType: ControlType.calendar,
  },
];
