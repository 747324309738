import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { SynonymEditorModule } from 'synonym/features';
import { DiagnosisDataAccessModule } from 'diagnosis/data-access';
import { DiagnosisEditorComponent } from './editor.component';

const COMPONENTS = [DiagnosisEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule.forRoot(),
    DiagnosisDataAccessModule,
    SynonymEditorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class DiagnosisEditorModule {}
