import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { objectToQuerystring } from '@identic/api';
import { BaseSubscriptions, buildPath } from '@identic/core';
import { ReportConstants, ReportService } from 'dashboard/data-access';
import { DataSourceViewModel } from 'data-source/data-access';
import { Required } from '../shared';

// R(oute)T(oken)
const RT = ReportConstants;

@Component({
  selector: 'report-table',
  template: `
    <ng-content><!-- Title goes here if provided --></ng-content>

    <ng-container *ngIf="(tableData$ | async); let tableData">
      <mat-table [dataSource]="tableData">
        <!-- Row definitions -->
        <mat-header-row class="row grid-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="row grid-data-row" *matRowDef="let row; columns: displayedColumns"></mat-row>

        <!-- Column definitions -->
        <ng-container *ngFor="let col of displayedColumns; let i=index" matColumnDef={{col}}>
          <!-- Header -->
          <mat-header-cell *matHeaderCellDef>
            {{cols[col]}}
          </mat-header-cell>

          <!-- Data -->
          <mat-cell *matCellDef="let row">
            {{row[col]}}
          </mat-cell>
        </ng-container>
      </mat-table>
    </ng-container>

    <ng-content select=".chart-footer"></ng-content>
`,
  styles: [`
  .default-graph { display: block; }  /* Full col container width */
  .aus-map-graph { height: 40vh; }
  .pie-graph { height: 10vh; }
`],
})
export class ReportTableComponent extends BaseSubscriptions implements OnInit, OnChanges {
  @Input() @Required species!: string | null;  //'canine' | 'feline';
  @Input() @Required reportRoute!: string;
  @Input() @Required startDate!: Date;
  @Input() @Required endDate!: Date;
  @Input() @Required cols: any;
  @Input() dataSource: DataSourceViewModel | null = null;
  @Input() topCount: number = -1;

  Object = Object;
  // typeof = typeof;

  tableData$: Observable<any> | undefined;
  displayedColumns: string[] = [];

  constructor(
    private service: ReportService,
  ) {
    super();  // For BaseSubscriptions
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayedColumns = Object.keys(this.cols);
    this.updateData();
  }

  ngOnInit(): void {
  }

  isNumber(value: any): boolean {
    return typeof(value) === typeof(1);
  }

  updateData(): void {
      const restful = buildPath(RT.SPECIES, this.species, this.reportRoute);
      const filters: any = {};

      if (this.dataSource?.id) {
        filters[RT.DATA_SOURCE] = this.dataSource?.id;
      }
      delete filters[RT.REGION];
      if (this.topCount > 0) {
        filters[RT.TOP] = this.topCount;
      }
      if (this.startDate) {
        filters[RT.START] = this.startDate;
      }
      if (this.endDate) {
        filters[RT.END] = this.endDate;
      }
      filters[RT.PERCENTAGES] = false;

      this.tableData$ = this.service.getReportData(restful+objectToQuerystring(filters));
  }
}
// console.log(`%cReportTableComponent::`, 'background:yellow');
