export const PatientConstants = {
  // Route Tokens
  defaultApi: 'Patient',

  // APPLICATION: "application",
  BREED: "breed",
  SPECIES: "species",
  WITH_FILTERS: "with-filters",


  // Other constants
  UI: {
    ROOT_PATH: 'patient',
  }

} as const;

// export const SHOW_PATIENTS_ACTION = {
//   actionName: 'show-patients-event',
//   title: 'Show Patients',
//   iconAwesome: 'fad fa-file-certificate fa-2x',
//   class: 'text-white bg-success',
// };
