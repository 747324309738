import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigService } from '@identic/core';

@Component({
  selector: 'acarc-root',
  template: `
<app-layout
  [loginButtonText]="loginButtonPrefix + 'Login'"
  [logoutButtonText]="loginButtonPrefix + 'Logout'"
  [shortTitle]="shortTitle" [siteLogoUrl]="siteLogoUrl"
  (logoClick)="onLogoClick()">
</app-layout>`
})
export class AppComponent {
  title = this.config.environment.SITE_TITLE;
  shortTitle = this.config.environment.SITE_SHORT_TITLE;
  siteLogoUrl = `assets/${this.config.environment.SITE_LOGO}`;
  // loginButtonPrefix = `<span class='fw-normal'>${this.title}</span><span class='mx-2 fw-normal'>|</span>`;
  loginButtonPrefix = `${this.title}<span class='mx-2'>|</span>`;

  constructor(
    private config: ConfigService,
    private router: Router
    ) {}

  onLogoClick(): void {
    // Navigate home
    this.router.navigate(['/']);
  }
}
