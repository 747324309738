<ng-container *ngIf="species$ | async; let species">
  <ng-container *ngIf="region$ | async; let region">
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-2">
          <button *ngIf="isPublic" mat-fab color="primary" (click)="onShowDashboardHelp()" title="Help">
            <i class="fas fa-circle-info fa-2x"></i>
          </button>
          <button *ngIf="isPublic" mat-fab color="primary" (click)="subscribe()" title="Subscribe to ACARCinom news and information" class="ms-2">
            <i class="far fa-envelope fa-2x"></i>
          </button>
          <button *ngIf="!isPublic" mat-fab color="primary" (click)="tutorials()" title="Tutorials" class="ms-2">
            <i class="fas fa-graduation-cap fa-2x"></i>
          </button>
          <data-source-selector *ngIf="!isPublic"></data-source-selector>
        </div>
        <div class="col">
          <h2 class="text-center mt-2">
            <span [innerHTML]="(isPublic ? (regionName$ | async) : (selectedDataSource$ | async)?.name) | formatDashboardHeader:species"></span> cancers
          </h2>
          <h6 class="text-center mt-2">
            <span [innerHTML]="(filtersDisplay$ | async)"></span>
          </h6>
        </div>
        <div class="col-2">
          <span class="d-flex species-select">
            <a *ngFor="let speciesButton of [RT.CANINE,RT.FELINE]" mat-fab class="m-2 mt-0 species-choice-btn" (click)="onChangeSpecies(speciesButton)" [class.pressed]="species === speciesButton" [title]="((species === speciesButton) ? 'Showing ':'Show ')+speciesButton">
              <i class="text-secondary fas fa-{{(speciesButton === RT.CANINE) ? 'dog': 'cat'}} fa-2x"></i>
            </a>
            <ng-container *ngIf="!isPublic">
              <button mat-fab color="primary" class="m-2 mt-0" (click)="onDownloadFile('Excel', species)" title="Download as Excel">
                <i class="fas fa-file-excel fa-2x"></i>
              </button>
              <button mat-fab color="primary" class="m-2 mt-0" (click)="onDownloadFile('PDF', species)" title="Download annual report">
                <i class="fas fa-file-pdf fa-2x"></i>
              </button>
            </ng-container>
            <button *ngIf="filters$ | async" mat-fab color="primary" class="m-2 mt-0" (click)="onClearFilters()" title="Clear all filters">
              <i class="fas fa-filter-slash fa-2x"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="row">
        <ng-container *ngFor="let dashboardItem of (dashboardItems$ | async)">
          <ng-container [ngSwitch]="dashboardItem">

            <mat-card *ngSwitchCase="RT.MAP_DATA" class="col-12 shadow-none">
              <mat-card-content>
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="800"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [chartOptions]="ausMapChartOptions"
                      [reportRoute]="RT.MAP_DATA"
                      (onClick)="graphClicked(dashboardItem, RT.MAP_DATA, $event)">
                </report-chart>
              </mat-card-content>
            </mat-card>

            <div *ngSwitchCase="'spacer'" [class]="widgetClass">
            </div>

            <!-- Reports -->
            <mat-card *ngSwitchCase="RT.DIAGNOSIS" [class]="widgetClass">
              <mat-card-content *ngIf="5; let topCount">
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="605"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [isPublic]="isPublic"
                      [topCount]="topCount"
                      [chartOptions]="diagnosisChartOptions"
                      [reportRoute]="RT.DIAGNOSIS"
                      (onClick)="graphClicked(dashboardItem, RT.DIAGNOSIS, $event)">
                  <div class="d-flex">
                    <h3 class="mx-auto">Top {{topCount}} types (morphology)</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="RT.TOPOGRAPHY" [class]="widgetClass">
              <mat-card-content *ngIf="10; let topCount">
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [isPublic]="isPublic"
                      [topCount]="topCount"
                      [chartOptions]="topographyChartOptions"
                      [reportRoute]="RT.TOPOGRAPHY"
                      (onClick)="graphClicked(dashboardItem, RT.TOPOGRAPHY, $event)">
                  <div class="d-flex">
                    <h3 class="mx-auto">Top {{topCount}} anatomical sites (topography)</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="RT.BREED" [class]="widgetClass">
              <mat-card-content *ngIf="10; let topCount">
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [isPublic]="isPublic"
                      [topCount]="topCount"
                      [chartOptions]="breedChartOptions"
                      [reportRoute]="RT.BREED"
                      (onClick)="graphClicked(dashboardItem, RT.BREED, $event)">
                  <div class="d-flex">
                    <h3 class="mx-auto">Top {{topCount}} breeds</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="RT.GENDER" [class]="widgetClass">
              <mat-card-content>
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [isPublic]="isPublic"
                      [chartOptions]="genderChartOptions"
                      [reportRoute]="RT.GENDER">
                  <div class="d-flex">
                    <h3 class="mx-auto">Sex</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="RT.AGE" [class]="widgetClass">
              <mat-card-content>
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [isPublic]="isPublic"
                      [chartOptions]="ageChartOptions"
                      [reportRoute]="RT.AGE">
                  <div class="d-flex">
                    <h3 class="mx-auto">Average age at diagnosis</h3>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>

            <mat-card *ngSwitchCase="'monthly-trend'" [class]="widgetClass">
              <mat-card-content>
                <report-chart class="center-chart"
                      [id]="dashboardItem"
                      graphHeight="600"
                      [species]="species"
                      [dataSource]="isPublic ? null : (selectedDataSource$ | async)"
                      [startDate]="trendStartDate"
                      [endDate]="trendEndDate"
                      [isPublic]="isPublic"
                      [chartOptions]="trendChartOptions"
                      [reportRoute]="RT.CASE">
                  <div class="d-flex">
                    <h3 class="mx-auto">Monthly Trend</h3>
                  </div>
                  <div class="d-flex justify-content-around">
                    <form [formGroup]="monthlyTrendFG" class="container row">
                        <ng-container *ngFor="let col of monthlyTrendFields!" >
                          <form-group-control [class]="col.detailClass" currentView="detail"
                              [col]="col" [readOnly]="col.detailEdit === false" [allowInvalid]="col.data?.allowInvalid"
                              [maxLen]="col.detailMaxLen"
                              [dataItem]="monthlyTrendFG">
                          </form-group-control>
                        </ng-container>
                    </form>
                  </div>
                </report-chart>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
<footer>
  <template-display templateName="PublicAcknowledgements"></template-display>
</footer>
