import { Component, OnInit } from '@angular/core';
import { FormControlStatus, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, map, take } from 'rxjs';

import { PagedResult } from '@identic/api';
import { BaseGridEditComponent, BusyDialogComponent, ConfirmDialogComponent, ControlType, CreateSelectOptionsFromList, CustomActionEvent, DialogData,
  ErrorDialogComponent,
  GridEditorComponent, GridEditorField, GridEditorPageStateService, MatFileWithUploadEventsEnum, SelectOption, VMasFG, ValidationErrorMessage, ViewChangeEvent, getAllValidationErrors, markControlsTouched } from '@identic/controls';
import { ConfigService, ErrorService, KeyValuePairs, SaveResponseAsFile, buildPath, ensureJson, numberOfDays, setSafeValue, toDate } from '@identic/core';
import { BreedService, BreedShallowViewModel, BREED_DISPLAY_FIELD, BREED_KEY_FIELD } from 'breed/data-access';
import { CaseViewModel, formatDaysAsYears } from 'case/data-access';
import { CertaintyLevelService, CertaintyLevelShallowViewModel, CERTAINTY_LEVEL_DISPLAY_FIELD, CERTAINTY_LEVEL_KEY_FIELD } from 'certainty-level/data-access';
import { DashboardConstants, ReportService } from 'dashboard/data-access';
import { DataSourceService, DataSourceViewModel, DATA_SOURCE_DISPLAY_FIELD, DATA_SOURCE_KEY_FIELD } from 'data-source/data-access';
import { DiagnosisService } from 'diagnosis/data-access';
import { GRADING_DISPLAY_FIELD, GradingViewModel, GRADING_KEY_FIELD, GradingService } from 'grading/data-access';
import { ImportViewModel, ImportFacade, ImportService, IMPORT_DISPLAY_FIELD, DELETE_SELECTED_IMPORTS_ACTION, COMMIT_SELECTED_IMPORTS_ACTION,
  ImportReadyStatus, ImportParseStatus, ImportDuplicateStatus, ImportConstants, EXCEL_IMPORT_ACTION } from 'import/data-access';
import { PatientViewModel } from 'patient/data-access';
import { SampleMetastasisService, SampleShallowViewModel, SampleViewModel } from 'sample/data-access';
import { SpeciesService, SpeciesViewModel, SPECIES_DISPLAY_FIELD, SPECIES_KEY_FIELD } from 'species/data-access';
import { TopographyService } from 'topography/data-access';
import { caseFields, CASE_SAMPLES_FIELD, CASE_DISPLAY_AGE_FIELD, CASE_PATIENT_FIELD, REPORT_DATA_FIELD } from './case-editor-fields.data';
import { COMMIT_MESSAGES_FIELD, DATA_SOURCE_DDL_FIELD, DUPLICATE_STATUS_FIELD, listFields, PARSE_STATUS_FIELD, PROCESSED_JSON_FIELD, READY_STATUS_FIELD, SOURCE_FILENAME_FIELD, SPECIES_FIELD } from './editor-fields.data';
import { PATIENT_BREED_DDL_FIELD, PATIENT_CROSS_BREED_DDL_FIELD, PATIENT_DATA_SOURCE_DDL_FIELD, patientFields, PATIENT_SPECIES_DDL_FIELD } from './patient-editor-fields.data';
import { SAMPLE_CERTAINTY_LEVEL_DDL_FIELD, SAMPLE_GRADE_DDL_FIELD, sampleFields } from './sample-editor-fields.data';
import { SampleValidationEvent } from 'sample/features';

// // R(oute)T(oken)
const RT = ImportConstants;

// Variables needed for BaseGridEditComponent calling CreateNewFormGroupItem
let COMPONENT_THIS: any = {};

@Component({
  selector: 'import-grid-edit',
  templateUrl: 'editor.component.html',
  // styles:[`
  // .save-btn-invalid { background-color: red !important; }
  // `]
})
export class ImportEditorComponent extends BaseGridEditComponent<ImportViewModel> implements OnInit {
  listName = 'Import';
  initialSortField = IMPORT_DISPLAY_FIELD;
  itemFields = listFields;
  needsSaving = false;
  samplesInvalid = false;
  addingSample = false;
  selectedIds: string[] = [];
  applicationName: string = this.config.environment.APP_NAME;

  detailPatient?: PatientViewModel;
  detailCase?: CaseViewModel;
  cachedSpeciesBreed: KeyValuePairs<SelectOption[]> = {};

  displayValueFns: KeyValuePairs<Function> = {};
  displayClassFns: KeyValuePairs<Function> = {};

  sampleValidationErrors: ValidationErrorMessage[] = [];

  // For use in template
  ensureJson = ensureJson;
  listFields = listFields;
  caseFields = caseFields;
  patientFields = patientFields;
  sampleFields = sampleFields;
  fileUploadFG: FormGroup | undefined;
  PATIENT_SPECIES_DDL_FIELD = PATIENT_SPECIES_DDL_FIELD;
  SOURCE_FILENAME_FIELD = SOURCE_FILENAME_FIELD;
  REPORT_DATA_FIELD = REPORT_DATA_FIELD;

  caseFG: FormGroup | undefined;
  patientFG: FormGroup | undefined;

  dropdownsToLoad: any = {
    certaintyLevel: true,
    dataSources: true,
    grades: true,
    speciesBreed: true,
  };
  private _allDropdownsLoadedSubject = new BehaviorSubject<boolean>(false);
  allDropdownsLoaded$ = this._allDropdownsLoadedSubject.asObservable();

  constructor(
    // Required for page state management
    public gridEditorPageStateService: GridEditorPageStateService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private reportService: ReportService,

    // For patient
    private breedService: BreedService,
    dataSourceService: DataSourceService,
    speciesService: SpeciesService,

    // For samples
    certaintyLevelService: CertaintyLevelService,
    diagnosisService: DiagnosisService,
    gradeService: GradingService,
    topographyService: TopographyService,
    sampleMetastasisService: SampleMetastasisService,

    private config: ConfigService,
    public service: ImportService,
    facade: ImportFacade,
  ) {
    super(route, facade, { service, topographyService, sampleMetastasisService, diagnosisService });

    COMPONENT_THIS = this; // For use in CreateNewFormGroupItem called from BaseGridEditComponent

    // For patient
    this.subscriptions.push(dataSourceService.getAll({ sort: DATA_SOURCE_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<DataSourceViewModel>) => {
      const dataSourceDdlField = listFields.find((i: GridEditorField) => i.field === DATA_SOURCE_DDL_FIELD)!;
      dataSourceDdlField.options = CreateSelectOptionsFromList(DATA_SOURCE_DISPLAY_FIELD, pagedResults.results, DATA_SOURCE_KEY_FIELD);

      const patientDataSourceDdlField = patientFields.find((i: GridEditorField) => i.field === PATIENT_DATA_SOURCE_DDL_FIELD)!;
      patientDataSourceDdlField.options = [...dataSourceDdlField.options];
      this.checkAllDropdownsAreNowLoaded(this.dropdownsToLoad.dataSources = false);
    }));

    this.subscriptions.push(speciesService.getAll({ sort: SPECIES_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<SpeciesViewModel>) => {
      const speciesDdlField = this.itemFields.find((i: GridEditorField) => i.field === SPECIES_FIELD)!;
      speciesDdlField.options = CreateSelectOptionsFromList(SPECIES_DISPLAY_FIELD, pagedResults.results, SPECIES_KEY_FIELD);

      const patientSpeciesDdlField = patientFields.find((i: GridEditorField) => i.field === PATIENT_SPECIES_DDL_FIELD)!;
      patientSpeciesDdlField.options = [...speciesDdlField.options];

      // Get cached Species-Breeds
      speciesDdlField.options.forEach(species => {
        this.breedService.getAll({ sort: BREED_DISPLAY_FIELD, pageSize: -1, endpoint: buildPath(RT.SPECIES, species.value)})
          .pipe(take(1))  // Saves having to unsubscribe
          .subscribe((pagedResults: PagedResult<BreedShallowViewModel>) => {
            this.cachedSpeciesBreed[species.value] = CreateSelectOptionsFromList(BREED_DISPLAY_FIELD, pagedResults.results, BREED_KEY_FIELD);
            if (Object.keys(this.cachedSpeciesBreed).length >= 1) {
              this.checkAllDropdownsAreNowLoaded(this.dropdownsToLoad.speciesBreed = false);
            }
          })
        });
    }));

    // For samples
    this.subscriptions.push(certaintyLevelService.getAll({ sort: CERTAINTY_LEVEL_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<CertaintyLevelShallowViewModel>) => {
      sampleFields.find((i: GridEditorField) => i.field === SAMPLE_CERTAINTY_LEVEL_DDL_FIELD)!.options = CreateSelectOptionsFromList(CERTAINTY_LEVEL_DISPLAY_FIELD, pagedResults.results, CERTAINTY_LEVEL_KEY_FIELD);
      this.checkAllDropdownsAreNowLoaded(this.dropdownsToLoad.certaintyLevel = false);
    }));

    this.subscriptions.push(gradeService.getAll({ sort: GRADING_DISPLAY_FIELD, pageSize: -1 }).subscribe((pagedResults: PagedResult<GradingViewModel>) => {
      sampleFields.find((i: GridEditorField) => i.field === SAMPLE_GRADE_DDL_FIELD)!.options = CreateSelectOptionsFromList(GRADING_DISPLAY_FIELD, pagedResults.results, GRADING_KEY_FIELD);
      this.checkAllDropdownsAreNowLoaded(this.dropdownsToLoad.grades = false);
    }));

    this.displayValueFns[SPECIES_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${item.species??'Unknown'}' class='fas fa-2xl ${(item.species === RT.CANINE) ? 'text-primary fa-dog' : 'text-success fa-cat'}'>&nbsp;</i>`;
    this.displayValueFns[READY_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${ImportReadyStatus[item.ready_status??ImportReadyStatus.Check]}' class='fas fa-2xl ${(item.ready_status === ImportReadyStatus.Check) ? 'fa-triangle-exclamation text-warning' : 'fa-check text-success'}'>&nbsp;</i>`;
    this.displayValueFns[PARSE_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${ImportParseStatus[item.parse_status??ImportParseStatus.Failed]}' class='fas fa-2xl ${(item.parse_status === ImportParseStatus.Failed) ? 'fa-user-robot-xmarks text-danger' : 'fa-user-robot text-success'}'>&nbsp;</i>`;
    this.displayValueFns[DUPLICATE_STATUS_FIELD] = (col: GridEditorField, item: ImportViewModel) => `<i title='${ImportDuplicateStatus[item.duplicate_status??ImportDuplicateStatus.Unchecked]}' class='fas fa-2xl ${(item.duplicate_status === ImportDuplicateStatus.Unique) ? 'fas fa-fingerprint text-success' : 'fas fa-fingerprint text-danger'}'>&nbsp;</i>`;
  }

  checkAllDropdownsAreNowLoaded(dummyArgForCalc: any): void {
    this._allDropdownsLoadedSubject.next(!Object.values(this.dropdownsToLoad).some(value => value === true));
  }

  override CreateNewFormGroupItem(itemData: ImportViewModel | any = {}): FormGroup {
    const itemFG = super.CreateNewFormGroupItem(itemData);

    if (itemData.processed_json) {

      let caseItemData: CaseViewModel = JSON.parse(itemData.processed_json??'{}');

      if (caseItemData.consultation_date && (caseItemData.age || caseItemData.patient?.dob)) {
        const ageInDays = caseItemData.age ? caseItemData.age : numberOfDays(toDate(caseItemData.consultation_date)!, toDate(caseItemData.patient!.dob)!) + 1;
        if (!caseItemData.age) {
          caseItemData.age = ageInDays;
        }
        (<any>caseItemData)[CASE_DISPLAY_AGE_FIELD] = `${caseItemData.age} days (${formatDaysAsYears(caseItemData.age)})`;
      }
      COMPONENT_THIS.caseFG = VMasFG(caseItemData, caseFields);

      // Hook sample changes
      COMPONENT_THIS.subscriptions.push(COMPONENT_THIS.caseFG.get(CASE_SAMPLES_FIELD)!.valueChanges.subscribe((_: any) => {
        COMPONENT_THIS.needsSaving = true;
      }));

      COMPONENT_THIS.patientFG = VMasFG(caseItemData.patient, patientFields);
      itemFG.setControl(CASE_PATIENT_FIELD, COMPONENT_THIS.patientFG);

      // Set breed options first time
      COMPONENT_THIS.setBreedOptionsForSpecies(caseItemData.patient?.species_id, COMPONENT_THIS.patientFG);
      // Hook species changes
      COMPONENT_THIS.subscriptions.push(COMPONENT_THIS.patientFG.get(PATIENT_SPECIES_DDL_FIELD)!.valueChanges.subscribe((speciesId: string) => COMPONENT_THIS.setBreedOptionsForSpecies(speciesId, COMPONENT_THIS.patientFG)));

    }

    // Hook changes
    COMPONENT_THIS.subscriptions.push(itemFG.valueChanges.subscribe((_v: any) => {
      if (itemFG.pristine) { return; }
      COMPONENT_THIS.needsSaving = true;
    }));

    return itemFG;
  }

  setBreedOptionsForSpecies(speciesId: string | undefined, patientFG: FormGroup): void {
    if (!speciesId) { return; }

    const breedSelectOptions = COMPONENT_THIS.cachedSpeciesBreed[speciesId];

    // Change the breed and cross-breed options to only be of that species
    [PATIENT_BREED_DDL_FIELD, PATIENT_CROSS_BREED_DDL_FIELD].forEach(breedFldName => {
      // Set options
      patientFields.find((i: GridEditorField) => i.field === breedFldName)!.options = breedSelectOptions;

      // Choose option by value
      const breedFld = patientFG.get(breedFldName)!;
      // Clear value if not in list
      if (breedSelectOptions.findIndex((b: SelectOption) => b.value === breedFld.value) < 0) {
        breedFld.patchValue(null);
      }

      // Display value after options set
      breedFld.updateValueAndValidity();
      });
  }

  onBlurUpload(event: any): void {console.log(`%cImportGridEditComponent::onBlurUpload`, 'background:cyan', event);}
  onChangeUpload(event: any): void {console.log(`%cImportGridEditComponent::onChangeUpload`, 'background:cyan', event);}
  onFocusUpload(event: any): void {console.log(`%cImportGridEditComponent::onFocusUpload`, 'background:cyan', event);}

  // -------------
  // Utils

  // // TODO: Remove after testing
  // override preloadCallback(): void {
  //   this.loadOverride = false;
  //   this.addFilter({ field: 'reference_code', matchMode: 'equals', value: ['21/0042'] });
  // }

  onUploadEvent(event: any, col: GridEditorField): void {
    if (col.controlType !== ControlType.file) {
      return;
    }

    if (+event.type === MatFileWithUploadEventsEnum.onUploaded) {
      this.dialog.open(ConfirmDialogComponent, <DialogData>{
        data: {
          title: `File uploaded`,
          icon: { faIcon: "fa-3x far fa-check-circle text-success" },
          iconText: `The Excel file has been uploaded`,
          text: `If there are any processing errors you willl be notified separately`,
          noCancelBtn: true,
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe();
    }
  }

  returnToGrid(detailItemFG: FormGroup | undefined): void {
    detailItemFG = undefined;
    this.currentView = 'grid';
    COMPONENT_THIS.needsSaving = false;
  }

  onCloseDetailItem(detailItemFG: FormGroup | undefined, caseItemFG: FormGroup): void {
    if (COMPONENT_THIS.needsSaving) {
      this.dialog.open(ConfirmDialogComponent, <DialogData>{
        data: {
          title: `Unsaved Changes`,
          icon: {
            iconColour: 'text-warning',
            matIcon: 'warning'
          },
          iconText: `Your changes have NOT been saved`,
          text: `Are you sure that you want to exit without saving?`,
          noText: 'No',
          yesText: 'Yes'
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe(exitConfirmed => {
        if (exitConfirmed) {
          this.returnToGrid(detailItemFG);
        }
      });
    } else {
      this.returnToGrid(detailItemFG);
    }
  }


  formatValidationErrors(validationErrors: ValidationErrorMessage[]): string {
    const rows = validationErrors
        .map((error: ValidationErrorMessage) => `
            <div class="row">
              <div class="col-4 border fw-bold">${error.field}</div>
              <div class="col-8 border">${error.errorMessage}</div>
            </div>
        `)
        .join('');

    return `
      <div class="container-fluid">
        ${rows}
      </div>
    `;
  }

  onCommitItem(detailItemFG: FormGroup, caseItemFG: FormGroup, importGridEditorComponent: GridEditorComponent): void {
    this.onSaveItem(detailItemFG, caseItemFG, false);

    if (detailItemFG.valid) {
      this.selectedIds = [detailItemFG.get('id')!.value];
      this.commitItems(importGridEditorComponent);
    } else {
      this.showValidationErrorsPopup([...getAllValidationErrors(detailItemFG!), ...this.sampleValidationErrors], 'This record cannot be committed because it is invalid');
    }
  }

  onSaveItem(detailItemFG: FormGroup | undefined, caseItemFG: FormGroup, returnToGrid: boolean = false): void {
    // Rebuild processed_json
    const processedJson: any = caseItemFG.getRawValue();
    processedJson.patient = this.patientFG?.getRawValue();
    setSafeValue(processedJson, 'patient.postcode', this.removeNonDigits(processedJson?.patient?.postcode));
    if (!processedJson.patient.postcode.length) {
      processedJson.patient.postcode = null;
    }
    detailItemFG!.get(PROCESSED_JSON_FIELD)?.patchValue(JSON.stringify(processedJson));

    const itemData = detailItemFG!.getRawValue();
    delete itemData.patient;

    const busyDialog = this.dialog.open(BusyDialogComponent, {
      data: {
        title: `Saving Import Record`,
        icon: {
          iconColour: 'text-info',
          matIcon: 'hourglass_top',
        },
        data: {style: 'spinner'},
        iconText: 'Updating import data changes...',
        text: `Please wait`,
      }
    });

    // Handle errors here not in the error well
    this.errorService.showInPopup({
      errorDialog: { iconText: `Unable to update import data` },
      onError: () => {
        busyDialog?.close();
      }
    });

    this.service.update(itemData)
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe((updatedItem: ImportViewModel) => {
        busyDialog?.close();
        // Refresh data
        super.loadPage();
        if (returnToGrid) {
          this.returnToGrid(detailItemFG);
        } else {
          detailItemFG?.get(READY_STATUS_FIELD)?.patchValue(updatedItem[READY_STATUS_FIELD]);
          detailItemFG?.get(COMMIT_MESSAGES_FIELD)?.patchValue(updatedItem[COMMIT_MESSAGES_FIELD]);
          // Display updated values
          detailItemFG?.updateValueAndValidity();
          this.needsSaving = false;

          markControlsTouched(detailItemFG!);
        }
      });
  }

  onDownloadOriginalExcelFile(detailItemFG: FormGroup, fieldName: string): void {
    this.errorService.showInPopup({ errorDialog: { iconText: `Unable to download Excel file from archive`}});
    this.reportService.downloadArchivedFile(detailItemFG.get('id')?.value, false)
      .pipe(
        take(1),  // Saves having to unsubscribe
        map((response: any) => SaveResponseAsFile(response))
      )
      .subscribe();
  }

  onPopupOriginalExcelData(detailItemFG: FormGroup, fieldName: string): void {
    const popupRef = this.dialog.open(ConfirmDialogComponent, <DialogData>{
      panelClass: ['excel-source-dialog'],
      data: {
        title: `Original Excel Data`,
        // text: fieldName + detailItemFG.get('raw_json')?.value,
        text: this.formatRawJson(detailItemFG.get('raw_json')?.value),
        noCancelBtn: true,
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe();
  }

  formatRawJson(rawJson: string): string {
    const jsonObj = JSON.parse(rawJson);
    // Convert JSON object into an array of key-value pairs for the mat-table data source
    const rows = Object.entries(jsonObj)
        .map(([key, value]) => `
            <div class="row">
              <div class="col-2 border fw-bold">${key}</div>
              <div class="col-10 border">${value}</div>
            </div>
        `)
        .join('');

    return `
        <div class="container-fluid">
          ${rows}
        </div>
    `;
  }

  removeNonDigits(str: string) {
    return (`${str}`).replace(/\D+/g, '');
  }

  onDeleteItem(detailItemFG: FormGroup, importGridEditorComponent: GridEditorComponent): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Confirm ${this.listName} Delete`,
        icon: {
          iconColour: 'text-danger',
          matIcon: 'delete'
        },
        // iconText: this.service.displayValue(item),
        text: `Are you sure that you want to delete this ${this.listName.toLowerCase()}?`,
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe(deleteConfirmed => {
      if (deleteConfirmed) {
        super.onDelete(detailItemFG.getRawValue());

        // Focus to previous item. If it's the first item deleted then return to the grid
        if (importGridEditorComponent.detailItemIndex > 0) {
          importGridEditorComponent.prevDetailItem();
        } else {
          this.returnToGrid(detailItemFG);
        }
      }
    });
  }

  // onSampleValidationErrors(errors: ValidationErrorMessage[] | any): void {
  //   this.sampleValidationErrors = errors;
  //   this.sampleValidationErrors.forEach(error => {
  //     if (error.field === 'undefined') {
  //       error.field = 'samples';
  //     }
  //   });
  //   // this.showValidationErrorsPopup(errors, 'The samples cannot be saved because they are invalid');
  // }

  onViewChangeSample(event: ViewChangeEvent): void {
    this.addingSample = event.view === 'detail';
  }

  onStatusChangeSample(event: SampleValidationEvent): void {
    this.samplesInvalid = (event.validationStatus === 'INVALID');
    this.sampleValidationErrors = event.validationErrors;
    this.sampleValidationErrors.forEach(error => {
      if (error.field === 'undefined') {
        error.field = 'samples';
      }
    });
  }

  onUpdateSample(caseItemFG: FormGroup, sample: SampleShallowViewModel) {
    if (this.samplesInvalid) {
      this.showValidationErrorsPopup(this.sampleValidationErrors, 'The sample cannot be saved because it is invalid');
      return;
    }
    const samples = caseItemFG.get(CASE_SAMPLES_FIELD);
    const sampleValues: SampleShallowViewModel[] = samples?.value??[];
    const sampleIdx = sampleValues.findIndex((s: SampleViewModel) => !s.id ? (s.sample_name === sample.sample_name) : (s.id === sample.id));
    if (sampleIdx === -1) {
      sampleValues.push(sample);
    } else {
      sampleValues[sampleIdx] = sample;
    }
    samples?.patchValue([...sampleValues]);
  }

  onDeleteSample(caseFG: FormGroup, sample: SampleShallowViewModel) {
    const samples = caseFG.get(CASE_SAMPLES_FIELD);
    const sampleValues: SampleShallowViewModel[] = samples?.value??[];
    samples?.patchValue(sampleValues.filter((s: SampleViewModel) => !s.id ? (s.sample_name !== sample.sample_name) : (s.id !== sample.id)));
  }

  onCustomAction(event: CustomActionEvent) {
    switch (event.actionName) {
      case EXCEL_IMPORT_ACTION.actionName:
        this.router.navigate([DashboardConstants.UI.ROOT_PATH, DashboardConstants.UI.UPLOAD_PATH]);
        break;


      case DELETE_SELECTED_IMPORTS_ACTION.actionName:
        if (this.selectedIds.length) {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: `Confirm Selected ${this.listName}(s) Delete`,
              icon: {
                iconColour: 'text-danger',
                matIcon: 'delete'
              },
              // iconText: this.service.displayValue(item),
              text: `Are you sure that you want to delete ${this.selectedIds.length} selected ${this.listName.toLowerCase()}s?`,
            }
          })
          .afterClosed()
          .pipe(take(1))  // Saves having to unsubscribe
          .subscribe(confirmed => {
            if (confirmed) {
              this.service.deleteMany(this.selectedIds)
                .pipe(take(1))  // Saves having to unsubscribe
                .subscribe(() => {
                  this.clearSelectedItems();
                  // Refresh data
                  super.loadPage();
                });
            }
          });
        }
        break;

      case COMMIT_SELECTED_IMPORTS_ACTION.actionName:
        this.commitItems();
        break;
      }
  }

  onSelectItems(addSelections: ImportViewModel[] = []): void {
    if (Array.isArray(addSelections)) {
      addSelections.forEach(a => this.selectedIds.push(a.id!));
    }
  }

  onUnselectItems(removeSelections: ImportViewModel[]): void {
    removeSelections.forEach(a => this.selectedIds = this.selectedIds.filter(p => p !== a.id));
  }

  clearSelectedItems(): void {
    this.selectedIds = [];
  }

  commitItems(importGridEditorComponent?: GridEditorComponent): void {
    if (this.selectedIds.length) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `Confirm Selected ${this.listName}(s) Commit`,
          icon: {
            iconColour: 'text-success',
            matIcon: 'cloud_upload'
          },
          text: `Are you sure that you want to commit ${this.selectedIds.length} ${this.listName.toLowerCase()}s?`,
        }
      })
      .afterClosed()
      .pipe(take(1))  // Saves having to unsubscribe
      .subscribe(confirmed => {
        if (confirmed) {
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: `Send email after Commit completed`,
              icon: {
                iconColour: 'text-info',
                matIcon: 'info'
              },
              text: `Would you like to be sent an email when the commit process has completed?`,
              noText: 'No',
              yesText: 'Yes'
            }
          })
          .afterClosed()
          .pipe(take(1))  // Saves having to unsubscribe
          .subscribe(emailUser => {
            this.service.commitMany(emailUser, this.selectedIds)
              .pipe(take(1))  // Saves having to unsubscribe
              .subscribe(() => {
                this.clearSelectedItems();
                // Refresh data
                super.loadPage();

                // Focus to previous item. If it's the first item committed then return to the grid
                if (importGridEditorComponent) {
                  if (importGridEditorComponent.detailItemIndex > 0) {
                    importGridEditorComponent.prevDetailItem();
                  } else {
                    this.returnToGrid(importGridEditorComponent.detailItemFG);
                  }
                }
              });
          });

        }
      });
    }
  }

  showValidationErrorsPopup(validationErrors: ValidationErrorMessage[], popupMessage: string): void {
    this.dialog.open(ErrorDialogComponent, <DialogData>{
      data: {
        title: `Validation Error Summary`,
        icon: { faIcon: "fa-3x fas fa-circle-xmark text-danger" },
        iconText: popupMessage,
        text: this.formatValidationErrors(validationErrors),
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe();
  }
}
// console.log(`%cImportGridEditComponent::ngOnInit`, 'background:yellow');
