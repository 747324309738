import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CustomControlsModule } from '@identic/controls';
import { AuthGuard } from '@identic/auth';
import { DataSourceDataAccessModule } from 'data-source/data-access';
import { DataSourceSelectorModule,  DataSourceSelectorComponent, DATA_SOURCE_SELECTOR_PATH } from './selector';
import { DataSourceEditorComponent } from './editor';

const COMPONENTS = [DataSourceEditorComponent];

export const routerModuleForChild = RouterModule.forChild([
  { path: DATA_SOURCE_SELECTOR_PATH, component: DataSourceSelectorComponent },
  { path: '', component: DataSourceEditorComponent, canActivate: [AuthGuard] },
  { path: 'list', redirectTo: '', pathMatch: 'full' },
]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModuleForChild,
    CustomControlsModule.forRoot(),
    DataSourceDataAccessModule,
    DataSourceSelectorModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DataSourceModule {}
