import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'validation-errors',
  templateUrl: 'validation-errors.component.html',
})
export class ValidationErrorsComponent {
  @Input() itemFG!: AbstractControl;
}
// console.log(`%cValidationErrorsComponent::ngOnInit`, 'background:yellow');
