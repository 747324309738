import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, map, take } from 'rxjs';

import { AppAuthService } from '@identic/auth';
import { BaseSubscriptions, BlobToDownloadedFile, ConfigService, DateValidators, ErrorService, KeyValuePairs, NewKVP, buildPath, formatDate, today } from '@identic/core';
import { BusyDialogComponent, ConfirmDialogComponent, DataCollectionDialogComponent, DialogData, VMasFG } from '@identic/controls';
import { TemplateAnonService, TemplateViewModel } from '@identic/templates';
import { DashboardConstants, DashboardService, ReportConstants, ReportService } from 'dashboard/data-access';
import { DataSourceFacade, DataSourceViewModel } from 'data-source/data-access';
import { ReportChartSelectionService, ageChartOptions, ausMapChartOptions, breedChartOptions, diagnosisChartOptions, genderChartOptions, topographyChartOptions, trendChartOptions } from '../shared';
import { DATE_RANGE_END_FIELD, DATE_RANGE_START_FIELD, dateRangePopupFields, monthlyTrendFields } from './date-range-popup-fields.data';
import { UserSubscriptionConstants } from 'user-subscription/data-access';
import { TutorialConstants } from 'tutorial/data-access';

// R(oute)T(oken)
const RT = ReportConstants;

@Component({
  selector: 'dashboard',
  templateUrl: 'editor.component.html',
})
export class DashboardEditorComponent extends BaseSubscriptions implements OnInit {
  @Input() isPublic: boolean = false;
  dashboardItems$!: Observable<string>;

  _speciesSubject = new BehaviorSubject<string>(RT.CANINE);
  public species$ = this._speciesSubject.asObservable();

  _regionSubject = new BehaviorSubject<string>(RT.ALL_OF_AUSTRALASIA_CODE);
  public region$ = this._regionSubject.asObservable();
  _regionNameSubject = new BehaviorSubject<string>(RT.ALL_OF_AUSTRALASIA);
  public regionName$ = this._regionNameSubject.asObservable();
  _filtersDisplaySubject = new Subject<string>();
  public filtersDisplay$ = this._filtersDisplaySubject.asObservable();
  _filtersSubject = new Subject<KeyValuePairs<string>>();
  public filters$ = this._filtersSubject.asObservable();

  selectedDataSource$ = this.dataSourceFacade.selected$;
  selectedDataSource: DataSourceViewModel | any;
  selectedDataSourceName?: string = '';
  isLoggedIn$ = this.authService.isLoggedIn$;

  // widgetClass = 'col-6 border';
  widgetClass = 'col-6 shadow-none';
  isFeline: boolean = false;
  trendStartDate: Date = today(-365);
  trendEndDate: Date = today();
  defaultStartDate: Date = today(-365);
  defaultEndDate: Date = today();
  // dataFilters: any = {};
  homePageHelpText: any;
  monthlyTrendFG! : FormGroup;

  // For use in template
  RT = ReportConstants;
  monthlyTrendFields = monthlyTrendFields;

  // Graph settings for template use
  diagnosisChartOptions = diagnosisChartOptions;
  topographyChartOptions = topographyChartOptions;
  ausMapChartOptions = ausMapChartOptions;
  breedChartOptions = breedChartOptions;
  genderChartOptions = genderChartOptions;
  ageChartOptions = ageChartOptions;
  trendChartOptions = trendChartOptions;

  buildPath = buildPath;
  formatDate = formatDate;

  constructor(
    config: ConfigService,
    templateService: TemplateAnonService,
    private router: Router,
    private authService: AppAuthService,
    private service: DashboardService,
    private dataSourceFacade: DataSourceFacade,
    private errorService: ErrorService,
    private selectionService: ReportChartSelectionService,
    private reportService: ReportService,
    private dialog: MatDialog,
  ) {
    super();  // For BaseSubscriptions

    this.monthlyTrendFG = VMasFG(NewKVP([[DATE_RANGE_START_FIELD, this.defaultStartDate],[DATE_RANGE_END_FIELD, this.defaultEndDate]]), monthlyTrendFields);
    [DATE_RANGE_START_FIELD, DATE_RANGE_END_FIELD].forEach((dateField: string) => {
      const dateFieldCtl = this.monthlyTrendFG.get(dateField)!;
      dateFieldCtl.setValidators([Validators.required, DateValidators.isDate]);
      this.subscriptions.push(dateFieldCtl.valueChanges.subscribe((value: Date) => {
        if (value && (value.getFullYear() > 1900) && (value.getFullYear() < 3000)) {
          if (dateField === DATE_RANGE_START_FIELD) {
            this.trendStartDate = value;
          } else {
            this.trendEndDate = value;
          }
        } else {
          dateFieldCtl.setErrors({ invalidDate: value });
        }
      }));
    });

    this.subscriptions.push(this.selectedDataSource$.subscribe((value: DataSourceViewModel) => {
      this.selectedDataSource = value;
      this.selectedDataSourceName = this.selectedDataSource?.reference_code;
    }));

    // Pre-get the help template
    this.subscriptions.push(templateService.get(config.environment.APP_NAME, 'HomePageHelp').subscribe((template: TemplateViewModel) => {
      this.homePageHelpText = template.template_body;
    }));
  }

  ngOnInit(): void {
    this.dashboardItems$ = this.service.getDashboardItems(this.isPublic);
  }

  onClearFilters(): void {
    this.clearDataFilters();
  }

  onChangeSpecies(species: string): void {
    this._speciesSubject.next(species);
    this.clearDataFilters();
  }

  onShowDashboardHelp(): void {
    this.dialog.open(ConfirmDialogComponent, <DialogData>{
      data: {
        title: `Cancer Data Portal Help`,
        // icon: { faIcon: "fas fa-circle-info fa-2x text-primary" },
        text: this.homePageHelpText,
        noCancelBtn: true,
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe();
  }

  onDownloadFile(fileType: 'PDF' | 'Excel', species: string): void {
    const filename = `acarcinom_export_${formatDate(today(), 'yyyy-MM-dd')}.${fileType === 'PDF' ? 'pdf': 'xlsx'}`;

    this.dialog.open(DataCollectionDialogComponent, <DialogData>{
      data: {
        title: `Date range`,
        icon: {
          iconColour: 'text-primary',
          matIcon: 'calendar_month'
        },
        iconText: 'Please enter the date range below to download.',
        dataCollectionFields: dateRangePopupFields,
        itemData: NewKVP([[DATE_RANGE_START_FIELD, this.defaultStartDate],[DATE_RANGE_END_FIELD, this.defaultEndDate]]),
      }
    })
    .afterClosed()
    .pipe(take(1))  // Saves having to unsubscribe
    .subscribe((dialogResponse: any) => {
      // dialogResponse is null if cancelled.
      if (dialogResponse) {
        // dialogResponse contains an object with values for each field in dataCollectionFields.
        const startDate = dialogResponse[DATE_RANGE_START_FIELD];
        const endDate = dialogResponse[DATE_RANGE_END_FIELD];
        if (startDate && endDate) {
          this.errorService.showInPopup({ errorDialog: { iconText: `Unable to download data`}});
          const displayDownloadType = (fileType === 'PDF') ? 'Report' : 'Excel file';
          const busyDialog = this.dialog.open(BusyDialogComponent, {
            data: {
              title: `Preparing ${displayDownloadType}`,
              icon: {
                iconColour: 'text-info',
                matIcon: 'hourglass_top',
              },
              data: {style: 'spinner'},
              iconText: 'Gathering the data ...',
              text: `Please wait`,
            }
          });

          // Handle errors here not in the error well
          this.errorService.showInPopup({
            errorDialog: { iconText: `Unable to download ${displayDownloadType}` },
            onError: () => {
              busyDialog?.close();
            }
          });

          const download$ = (fileType === 'PDF')
            ? this.reportService.downloadPdfFile(filename, buildPath(DashboardConstants.UI.ROOT_PATH, DashboardConstants.UI.REPORT_PATH, this.selectedDataSource?.id, species, formatDate(startDate), formatDate(endDate)), {
                  // tokens:
                  startDate,
                  endDate,
                  dataSourceName: this.selectedDataSource?.name?? 'unknown',
                  species
                })
            : this.reportService.downloadExcelFile(startDate, endDate, this.selectedDataSource?.id);

          download$.pipe(
              take(1),  // Saves having to unsubscribe
              map((response: any) => BlobToDownloadedFile(filename, response.body!))
            )
            .subscribe(() => {
              busyDialog?.close();
              this.dialog.open(ConfirmDialogComponent, <DialogData>{
                data: {
                  title: `${displayDownloadType} Download complete`,
                  icon: {
                    iconColour: 'text-success',
                    matIcon: 'check_circle_outline',
                  },
                  iconText: `Your file '${filename}' has been successfully downloaded`,
                  text: 'Check in your downloads folder',
                  noCancelBtn: true,
                }
              })
              .afterClosed()
              .pipe(take(1))  // Saves having to unsubscribe
              .subscribe();
            });
        }
      }
    });
  }

  subscribe(): void {
    this.router.navigate([UserSubscriptionConstants.UI.ROOT_PATH]);
  }

  tutorials(): void {
    this.router.navigate([TutorialConstants.UI.ROOT_PATH]);
  }

  graphClicked(graphId: string, filterType: string, event: any | MouseEvent): void {
    this.updateHeading();
  }

  clearDataFilters(excludeMap: boolean = false): void {
    const mapSelection = (excludeMap) ? this.selectionService.getSelection(RT.MAP_DATA) : null;
    this.selectionService.clearAllSelections();
    if (mapSelection) {
      this.selectionService.selectPoint(RT.MAP_DATA, mapSelection);
    }
    this.updateHeading();
  }

  updateHeading(): void {
    const mapSelection = this.selectionService.getSelection(RT.MAP_DATA);
    const topographySelection = this.selectionService.getSelection(RT.TOPOGRAPHY);
    const diagnosisSelection = this.selectionService.getSelection(RT.DIAGNOSIS);
    const breedSelection = this.selectionService.getSelection(RT.BREED);

    this._regionSubject.next(mapSelection?? RT.ALL_OF_AUSTRALASIA_CODE);
    this._regionNameSubject.next(mapSelection?? RT.ALL_OF_AUSTRALASIA);
    this._filtersDisplaySubject.next(((breedSelection??'') +' '+ (diagnosisSelection??'') +' '+ (topographySelection ? `of the ${topographySelection}` : '')).trim());

    const flatFilters = this.selectionService.getAllSelections();

    this._filtersSubject.next(flatFilters);
  }
}
// console.log(`%cDashboardGridEditComponent::ngOnInit`, 'background:yellow');
