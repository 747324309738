export const ReportConstants = {
  // Route Tokens
  defaultApi: 'Export',
  DOWNLOAD_PDF: 'download-pdf',

  AGE: 'age',
  ALL_OF_AUSTRALASIA_CODE: 'aus-nzl',
  ALL_OF_AUSTRALASIA: 'Australasian',
  BREED: 'breed',
  CANINE: 'Canine',
  CASE: 'case',
  DATA_SOURCE: 'data_source',
  DIAGNOSIS: 'diagnosis',
  END: 'end',
  EXCEL: 'excel',
  FELINE: 'Feline',
  GENDER: 'gender',
  IMPORT: 'import',
  MAP_DATA: 'map-data',
  MAP_POINT_DISPLAY_FIELD: 'name',
  MAP_POINT_KEY_FIELD: 'hc-key',
  PERCENTAGES:  'percentages',
  REGION: 'region',
  SPECIES: 'species',
  START: 'start',
  TOP: 'top',
  TOPOGRAPHY: 'topography',
  ACARCINOM_YELLOW: '#FCBB21',
  ACARCINOM_BROWN: '#A59E8A',
} as const;
