import { DataSourceViewModel } from "data-source/data-access";
import { PatientViewModel } from "patient/data-access";
import { SampleViewModel } from "sample/data-access";

export const CASE_KEY_FIELD = 'id';
export const CASE_DISPLAY_FIELD = 'reference_code';

/* For gen-fields
export interface CaseViewModel extends CaseShallowViewModel {
  id?: string; // GUID
  age?: number;
  consultation_date?: Date;
  data_source?: DataSourceViewModel;
  data_source_id?: string; // GUID
  date_added?: Date;
  patient?: PatientViewModel;
  patient_id?: string; // GUID
  reference_code?: string;
  report_data?: string;
  samples?: SampleViewModel[];
  source_filename?: string;
  source_row?: number;
}
*/

export interface CaseShallowViewModel {
  id?: string; // GUID
  age?: number;
  consultation_date?: Date;
  data_source_id?: string; // GUID
  date_added?: Date;
  patient?: PatientViewModel;
  patient_id?: string; // GUID
  reference_code?: string;
  source_filename?: string;
  source_row?: number;
  species?: string;
}

export interface CaseViewModel extends CaseShallowViewModel {
  // id?: string; // GUID
  // age?: number;
  // consultation_date?: Date;
  data_source?: DataSourceViewModel;
  // data_source_id?: string; // GUID
  // date_added?: Date;
  // patient?: PatientViewModel;
  // patient_id?: string; // GUID
  // reference_code?: string;
  report_data?: string;
  samples?: SampleViewModel[];
  // source_filename?: string;
  // source_row?: number;
}
