import { Routes } from '@angular/router';

import { adminRole, buildPath } from '@identic/core';
import { AuthGuard } from '@identic/auth';
import { MenuItems } from '@identic/layout';
import { IDENTIC_PRODUCT_SELECTOR_PATH, IdenticProductSelectorComponent, LicenceConstants, licenceMenuItems } from '@identic/licence';
import { LOGTODB_VIEWER_ROLE } from '@identic/log-to-db';

import { BreedConstants } from 'breed/data-access';
import { CaseConstants } from 'case/data-access';
import { CertaintyLevelConstants } from 'certainty-level/data-access';
import { DataSourceConstants } from 'data-source/data-access';
import { DiagnosisConstants } from 'diagnosis/data-access';
import { GradingConstants } from 'grading/data-access';
import { ImportConstants } from 'import/data-access';
import { LocationConstants } from 'location/data-access';
import { MessageConstants } from 'message/data-access';
import { PatientConstants } from 'patient/data-access';
import { SampleConstants } from 'sample/data-access';
import { SpeciesConstants } from 'species/data-access';
import { SynonymConstants } from 'synonym/data-access';
import { TopographyConstants } from 'topography/data-access';
import { DashboardConstants } from 'dashboard/data-access';
import { environment } from '../environments';
import { HomeComponent } from './home.component';
import { TutorialConstants } from 'tutorial/data-access';
import { UserSubscriptionConstants } from 'user-subscription/data-access';

const APP_ADMIN_ROLE = adminRole(environment.APP_NAME);
const LICENCE_USERS_PATH = buildPath(LicenceConstants.LICENCE, LicenceConstants.UI.PROJECT_USERS_PATH);
const DASHBOARD_UPLOAD_PATH = buildPath(DashboardConstants.UI.ROOT_PATH, DashboardConstants.UI.UPLOAD_PATH);
const DASHBOARD_REPORT_PATH = buildPath(DashboardConstants.UI.ROOT_PATH, DashboardConstants.UI.REPORT_PATH);

const TUTORIAL_PATH = [
  buildPath(TutorialConstants.UI.ROOT_PATH, '1'),
];

// Page options are here and referenced twice below in Routes & menuItems
// E.G. appointments: { text: 'Appointments', icon: 'people', path: 'appointments', routerLink: '/appointments/list', roles: 'SMS.Admin&&(Unit.EDU||Unit.SLC)' },

const Page = {
  // Core options
  home: { text: 'Home', icon: 'home', path: 'home', routerLink: 'home', application: 'ACARCinom'/*, loggedInOnly: true*/ },
  applicationSelect: { text: 'Change Application', icon: 'swap_horizontal_circle', path: IDENTIC_PRODUCT_SELECTOR_PATH, routerLink: IDENTIC_PRODUCT_SELECTOR_PATH, roles: APP_ADMIN_ROLE },
  emailLogs: { text: 'Email Logs', icon: 'library_books', path: 'logs/group/email', routerLink: 'logs/group/Email', roles: APP_ADMIN_ROLE },
  emailTemplates: { text: 'Edit Email Templates', icon: 'email', path: 'email-template', routerLink: 'email-template/list', roles: APP_ADMIN_ROLE },
  licences: { text: 'Licences', faIcon: 'fas fa-file-certificate fa-fw', path: 'licence', /*routerLink: 'licence',*/ roles: APP_ADMIN_ROLE, children: licenceMenuItems },
  logs: { text: 'ACARCinom Logs', icon: 'list', path: 'logs', routerLink: 'logs/list', roles: `${APP_ADMIN_ROLE}||${LOGTODB_VIEWER_ROLE}` },
  notifications: { text: 'Notifications', icon: 'record_voice_over', path: 'notifications', routerLink: 'notifications/list', roles: APP_ADMIN_ROLE },
  pageTemplates: { text: 'Edit Templates', icon: 'web', path: 'page-template', routerLink: 'page-template/list', roles: APP_ADMIN_ROLE },

  // Custom options
  breed: { text: 'Breed', icon: 'pets', path: BreedConstants.UI.ROOT_PATH, routerLink: BreedConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  case: { text: 'Case', faIcon: 'fas fa-suitcase-medical fa-fw', path: CaseConstants.UI.ROOT_PATH, routerLink: CaseConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  certaintyLevel: { text: 'CertaintyLevel', icon: 'verified', path: CertaintyLevelConstants.UI.ROOT_PATH, routerLink: CertaintyLevelConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  dashboard: { text: 'Dashboard', icon: 'dashboard', path: DashboardConstants.UI.ROOT_PATH, routerLink: DashboardConstants.UI.ROOT_PATH, loggedInOnly: true },
  dashboardUpload: { text: 'Upload Data', icon: 'upload', path: DASHBOARD_UPLOAD_PATH, routerLink: DASHBOARD_UPLOAD_PATH, loggedInOnly: true },
  dashboardReport: { text: '', icon: '', path: DASHBOARD_REPORT_PATH, routerLink: DASHBOARD_REPORT_PATH },
  dataSource: { text: 'DataSource', icon: 'analytics', path: DataSourceConstants.UI.ROOT_PATH, routerLink: DataSourceConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  diagnosis: { text: 'Diagnosis', faIcon: 'fas fa-stethoscope fa-fw', path: DiagnosisConstants.UI.ROOT_PATH, routerLink: DiagnosisConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  grading: { text: 'Grading', icon: 'trending_up', path: GradingConstants.UI.ROOT_PATH, routerLink: GradingConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  import: { text: 'Import', icon: 'upload_file', path: ImportConstants.UI.ROOT_PATH, routerLink: ImportConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  location: { text: 'Location', icon: 'place', path: LocationConstants.UI.ROOT_PATH, routerLink: LocationConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  message: { text: 'Message', icon: '', path: MessageConstants.UI.ROOT_PATH, routerLink: MessageConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  partnerUsers: { text: 'Partner Users', faIcon: 'fas fa-users fa-fw', path: LICENCE_USERS_PATH, routerLink: LICENCE_USERS_PATH, roles: APP_ADMIN_ROLE },
  patient: { text: 'Patient', faIcon: 'fas fa-cat-space fa-fw', path: PatientConstants.UI.ROOT_PATH, routerLink: PatientConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  sample: { text: 'Sample', faIcon: 'fas fa-vials fa-fw', path: SampleConstants.UI.ROOT_PATH, routerLink: SampleConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  species: { text: 'Species', icon: 'pets', path: SpeciesConstants.UI.ROOT_PATH, routerLink: SpeciesConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  synonym: { text: 'Synonym', faIcon: 'fas fa-list-tree fa-fw', path: SynonymConstants.UI.ROOT_PATH, routerLink: SynonymConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  topography: { text: 'Topography', faIcon: 'far fa-chart-network fa-fw', path: TopographyConstants.UI.ROOT_PATH, routerLink: TopographyConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  tutorials: { text: 'Tutorials', faIcon: 'fas fa-graduation-cap', path: TutorialConstants.UI.ROOT_PATH, routerLink: TutorialConstants.UI.ROOT_PATH, roles: APP_ADMIN_ROLE },
  userSubscription: { text: 'Subscribe', faIcon: 'far fa-envelope', path: UserSubscriptionConstants.UI.ROOT_PATH, routerLink: UserSubscriptionConstants.UI.ROOT_PATH, loggedOutOnly: true },
};

export const appRoutes: Routes = [
  // Core options
  { path: Page.home.path, redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent, data: Page.home },
  { path: 'auth-'+Page.applicationSelect.path, component: IdenticProductSelectorComponent, data: Page.applicationSelect, canActivate: [AuthGuard] },  // For direct links to force login first
  { path: Page.applicationSelect.path, component: IdenticProductSelectorComponent, data: Page.applicationSelect },
  { path: Page.emailLogs.path, loadChildren: () => import('@identic/log-to-db').then(m => m.LogToDbModule), data: Page.emailLogs },
  { path: Page.emailTemplates.path, loadChildren: () => import('@identic/email').then(m => m.EmailTemplateModule), data: Page.emailTemplates },
  { path: Page.licences.path, loadChildren: () => import('@identic/licence').then(m => m.LicenceModule), data: Page.licences },
  { path: Page.logs.path, loadChildren: () => import('@identic/log-to-db').then(m => m.LogToDbModule), data: Page.logs },
  { path: Page.notifications.path, loadChildren: () => import('@identic/notification').then(m => m.NotificationModule), data: Page.notifications },
  { path: Page.pageTemplates.path, loadChildren: () => import('@identic/templates').then(m => m.PageTemplateModule), data: Page.pageTemplates},

  // Custom options (Must come after Core options in Routes so they can access them)
  { path: Page.breed.path, loadChildren: () => import('breed/features').then(m => m.BreedModule), data: Page.breed },
  { path: Page.case.path, loadChildren: () => import('case/features').then(m => m.CaseModule), data: Page.case },
  { path: Page.certaintyLevel.path, loadChildren: () => import('certainty-level/features').then(m => m.CertaintyLevelModule), data: Page.certaintyLevel },
  { path: Page.dashboard.path, loadChildren: () => import('dashboard/features').then(m => m.DashboardModule), data: Page.dashboard },
  { path: Page.dashboardUpload.path, loadChildren: () => import('dashboard/features').then(m => m.DashboardModule), data: Page.dashboardUpload },
  { path: Page.dashboardReport.path, loadChildren: () => import('dashboard/features').then(m => m.DashboardModule), data: Page.dashboardReport },
  { path: Page.dataSource.path, loadChildren: () => import('data-source/features').then(m => m.DataSourceModule), data: Page.dataSource },
  { path: Page.diagnosis.path, loadChildren: () => import('diagnosis/features').then(m => m.DiagnosisModule), data: Page.diagnosis },
  { path: Page.grading.path, loadChildren: () => import('grading/features').then(m => m.GradingModule), data: Page.grading },
  { path: Page.import.path, loadChildren: () => import('import/features').then(m => m.ImportModule), data: Page.import },
  { path: Page.location.path, loadChildren: () => import('location/features').then(m => m.LocationModule), data: Page.location },
  { path: Page.message.path, loadChildren: () => import('message/features').then(m => m.MessageModule), data: Page.message },
  { path: Page.partnerUsers.path, loadChildren: () => import('@identic/licence').then(m => m.LicenceModule), data: Page.partnerUsers },
  { path: Page.patient.path, loadChildren: () => import('patient/features').then(m => m.PatientModule), data: Page.patient },
  { path: Page.sample.path, loadChildren: () => import('sample/features').then(m => m.SampleModule), data: Page.sample },
  { path: Page.species.path, loadChildren: () => import('species/features').then(m => m.SpeciesModule), data: Page.species },
  { path: Page.synonym.path, loadChildren: () => import('synonym/features').then(m => m.SynonymModule), data: Page.synonym },
  { path: Page.topography.path, loadChildren: () => import('topography/features').then(m => m.TopographyModule), data: Page.topography },
  { path: Page.tutorials.path, loadChildren: () => import('tutorial/features').then(m => m.TutorialsModule), data: Page.tutorials },
  { path: Page.userSubscription.path, loadChildren: () => import('user-subscription/features').then(m => m.UserSubscriptionModule), data: Page.userSubscription },
];

export const menuItems: MenuItems = [
  // User profile option
  {
    text: '',               // userDisplayName
    // path?: string;       // Profile path
    // routerLink?: string; // Profile path
    icon: 'person',
    title: 'User',
    isProfile: true,
  },

  Page.home,
  Page.userSubscription,
  Page.dashboard,
  Page.dashboardUpload,
  Page.patient,
  Page.case,
  Page.import,
  Page.tutorials,

  { text: 'Data Maintenance', icon: 'build', roles: APP_ADMIN_ROLE, children: [
      Page.breed,
      Page.certaintyLevel,
      Page.dataSource,
      Page.diagnosis,
      Page.grading,
      Page.location,
      // Page.message, // Not needed
      // Page.sample, // Can only get there from cases
      Page.species,
      // Page.synonym, // Not needed
      Page.topography,
    ]
  },

  { text: 'Admin', faIcon: 'fas fa-shield-quartered fa-fw', roles: APP_ADMIN_ROLE, children: [
      Page.notifications,
      {...Page.partnerUsers, separatorBefore: true },
      {...Page.pageTemplates, separatorBefore: true },
      Page.emailTemplates,
      {...Page.logs, separatorBefore: true },
      Page.emailLogs,
    ]
  },

];
