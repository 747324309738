import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SampleEffects } from './+state/sample.effects';
import { SampleFacade } from './+state/sample.facade';
import { SampleReducer } from './+state/sample.reducer';
import { SAMPLE_FEATURE_KEY, SampleInitialState as initialState } from './+state/sample.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForSample = StoreModule.forFeature(SAMPLE_FEATURE_KEY, SampleReducer, { initialState });
export const effectsModuleForSample = EffectsModule.forFeature([SampleEffects]);

const PROVIDERS = [SampleFacade];

@NgModule({
  imports: [
    CommonModule,
    storeModuleForSample,
    effectsModuleForSample,
  ],
  providers: PROVIDERS,
})
export class SampleDataAccessModule {}
